import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { colors } from "../../Shared/colors";
import Tooltip from "@mui/material/Tooltip";
import {
  useAddAffiliateCommentMutation,
  useDeleteAffiliateMutation,
  useLazyGetAffiliateLeadsQuery,
  useLazyGetAllAffiliateQuery,
  useVerifyAffiliateAgentMutation,
} from "../../features/affiliateSlice";
import { toast, Toaster } from "react-hot-toast";
import { Loader } from "../../Shared/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import { AffiliateLeadsDataTable } from "./AffiliateLeadsDataTable";
import { decryptData } from "../../services/crypto";

export const AffiliateDataTable = () => {

  const [pageSize, setPageSize] = useState(20);


  const [popUp, setpopUp] = useState(false)

  const [affType, setaffType] = useState("");

  const [comment, setcomment] = useState("")

  const [affComments, setaffComments] = useState(null)

  const [affiliateLoading, setaffiliateLoading] = useState(false);

  const [getAffLeads,{data:affLeads}] = useLazyGetAffiliateLeadsQuery()

  const [addComment] = useAddAffiliateCommentMutation()
  let [trigger, { data: Affiliate, isFetching }] =
    useLazyGetAllAffiliateQuery();

  useEffect(() => {
    trigger(affType);
  }, [affType]);


  const [verifyAffiliate] = useVerifyAffiliateAgentMutation();

  const [deleteAffiliate] = useDeleteAffiliateMutation();

  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  const handleVefiyAffiliate = (id) => {
    if (
      role === "hr" ||
      role === "v3" ||
      role === "developer" ||
      role === "ceo" ||
      role === "director"
    ) {
      setaffiliateLoading(true);
      verifyAffiliate(id)
        .unwrap()
        .then((res) => {
          toast.success("Affiliate Verified");
          setaffiliateLoading(false);
        })
        .catch((err) => {
          toast.error("Error Verifying Affiliate");
          setaffiliateLoading(false);
        });
    } else {
      toast.error("You dont have permission to verify affiliate");
    }
  };

  const handleDeleteAffiliate = (id) => {
    if (
      role === "hr" ||
      role === "v3" ||
      role === "developer" ||
      role === "ceo" ||
      role === "director"
    ) {
      setaffiliateLoading(true);
      deleteAffiliate(id)
        .unwrap()
        .then((res) => {
          toast.success("Affiliate deleted successfully");
          setaffiliateLoading(false);
        })
        .catch((err) => {
          toast.error("Error while deleting Affiliate");
          setaffiliateLoading(false);
        });
    } else {
      toast.error("You dont have permission to delete affiliate");
    }
  };

  const handleAffiliateLeads = (id) =>{
    getAffLeads(id).unwrap()
    .then((res)=>{
      toast.success("Affiliate Leads Loaded!")
    })
    .catch((err)=>{
      toast.error(err.data.error.message)
    })
  }

  const handleAddComment = (id) =>{
    let body = {
      comments:[comment]
    }
    addComment({id,body}).unwrap()
    .then((res)=>{
      toast.success("Comment added successfully!")
    })
    .catch((err)=>{
      toast.error(err.data.error.message)
    })
  }
  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      width: 200,
    },
    {
      field: "affiliateName",
      headerName: "Affiliate Name",
      width: 200,
      renderCell:(params)=>{
        return(
          <div onClick={()=>handleAffiliateLeads(params.row.id)} style={{color:colors.brown,cursor:"pointer"}}>{params.row.affiliateName}</div>
        )
      }
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 200,
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 200,
      renderCell:(params)=>{
        return(
          <Comment style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
            <button onClick={() => {
              setpopUp(true)
              setaffComments(params.row.comment)
            } }>view Comments</button>
          </Comment>
        )
      }
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.address}>
            <div>{params.row.address}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "officePic",
      headerName: "OfficePic",
      width: 200,
      renderCell: (params) => {
        return params.row.officePic ? (
          <a href={params.row.officePic} target="_blank">
            <Img>
              <img src={`${params.row.officePic}`} alt="avatar" />
            </Img>
          </a>
        ) : (
          <Img>No pic</Img>
        );
      },
    },
    {
      field: "businessCardPic",
      headerName: "Business Card Pic",
      width: 200,
      renderCell: (params) => {
        return params.row.businessCardPic ? (
          <a href={params.row.businessCardPic} target="_blank">
            <Img>
              <img src={`${params.row.businessCardPic}`} alt="avatar" />
            </Img>
          </a>
        ) : (
          <Img>No pic</Img>
        );
      },
    },
    {
      field: "verified",
      headerName: "Verfied",
      width: 200,
      renderCell: (params) => {
        return params.row.verified ? (
          <Verified>
            <div style={{ backgroundColor: `${colors.cold}` }}>Verified</div>
          </Verified>
        ) : (
          <Verified onClick={() => handleVefiyAffiliate(params.row.id)}>
            <div style={{ backgroundColor: `${colors.hot}` }}>Not verified</div>
          </Verified>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 110,
      renderCell: (params) => {
        return (
          <Action onClick={() => handleDeleteAffiliate(params.row.id)}>
            <DeleteIcon
              style={{ color: `${colors.textBlue}`, cursor: "pointer" }}
            />
          </Action>
        );
      },
    },
    role==='ceo'?
    {
      field: "addComment",
      headerName: "Add Comment",
      width: 200,
      renderCell: (params) => {
        return (
          <Comment>
            <input type="text" name="comment" id="comment" value={comment} onChange={(e)=>setcomment(e.target.value)} />
            <button onClick={() => handleAddComment(params.row.id)}>send</button>
          </Comment>
        );
      },
    }:null
  ];
  const rows = [];
  Affiliate?.forEach((affiliate) => {
    rows.push({
      id: affiliate._id,
      affiliateName: affiliate.affName,
      contact: affiliate.affContact,
      address: affiliate.affAddress,
      officePic: affiliate.officePic,
      businessCardPic: affiliate.businessCardPic,
      verified: affiliate.verified,
      comment:affiliate.comments,
      employee:
        affiliate?.employee?.firstName + " " + affiliate?.employee?.lastName,
    });
  });

  const filteredColumns = columns.filter((column) => column);

  return (
    <Main>
      <TableSection>
        <LeadsSection>
          <div>
            Total Affiliate:<span> {Affiliate?.length} </span>
          </div>
          <select
            name="affilationType"
            id="affilationType"
            onChange={(e) => setaffType(e.target.value)}
          >
            <option value="">Select</option>
            <option value="individual">Individual</option>
            <option value="agency">Agency</option>
          </select>
        </LeadsSection>
        <Table>
          <DataGrid
            rows={rows}
            columns={filteredColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
            sx={{
              border: "none",
            }}
          />
          {affiliateLoading ? <Loader /> : null}
        </Table>
        <Toaster />
      </TableSection>
        <AffiliateLeadsDataTable leads={affLeads}/>
        <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" style={{height:'50%'}}/>
          </Cross>
          <PopUpSection>
            <Section>
                {
                  affComments?.map((comment)=>(
                    <div>Comment: {comment}</div>
                  ))
                }
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;
const TableSection = styled.div`
  width: 100%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
  position: relative;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  select {
    outline: none;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Img = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
`;

const Verified = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    width: 60%;
    color: white;
    padding: 0.5em 0.2em;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  cursor: pointer;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;


const Comment = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
button{
  background-color: ${colors.brown};
  color: ${colors.white};
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
}
input{
  max-width: 8em;
}
`


const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;


const NumberPopUp = styled.div`
  width: 30vw;
  height: 30vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  height: 80%;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const NumberPopUpSection = styled(PopUpSection)`
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  line-height: 3em;
`;


const PopUp = styled.div`
  width: 40%;
  height: 40%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Section = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  div{
    margin-top: 1em;
    color: ${colors.brown};
  }
`;


