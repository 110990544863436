import { apiSlice } from "./api/apiSlice";

const policySlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createPolicy:builder.mutation({
            query:(policy)=>({
                url:'policy/create',
                method:'POST',
                body:policy
            }),
            invalidatesTags:['Policy']
        }),

        editPolicy:builder.mutation({
            query:(id,policy)=>({
                url:`policy/${id}`,
                method:'PUT',
                body:policy
            }),
            invalidatesTags:['Policy']
        }),

        deletePolicy:builder.mutation({
            query:(id)=>({
                url:`policy/${id}`,
                method:'DELETE'
            }),
            invalidatesTags:['Policy']
        }),

        getAllPolicy:builder.query({
            query:()=>({
                url:'policy'
            }),
            transformResponse:(reponse)=>reponse.policy,
            providesTags:['Policy']
        }),

        getSinglePolicy:builder.query({
            query:(id)=>({
                url:`policy/${id}`,
            }),
            providesTags:['Policy']
        })
    })
})


export const { useCreatePolicyMutation, useEditPolicyMutation, useDeletePolicyMutation, useGetAllPolicyQuery, useGetSinglePolicyQuery } = policySlice;