import React from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { DataGrid } from "@mui/x-data-grid";
import { Toaster } from "react-hot-toast";
import { Loader } from "../../../Shared/Loader";
import Tooltip from "@mui/material/Tooltip";
import { useGetAllActiveFormsQuery } from "../../../features/socialMediaSlice";

export const ActiveFormDataTable = () => {
  const { data: forms = [], isFetching: isFormLoading } = useGetAllActiveFormsQuery();

  const columns = [
    {
      field: "id",
      headerName: "Form ID",
      width:200,
      maxWidth: 200,
      renderCell: (params) => (
        <Client>
          <Tooltip title={params.row.id}>
            <div>{params.row.id}</div>
          </Tooltip>
        </Client>
      ),
    },
    {
      field: "name",
      headerName: "Project",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <div>{params.row.name}</div>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <div>{params.row.status}</div>
        </Tooltip>
      ),
    },
  ];

  return (
    <Main>
      <LeadsSection>
        Total Active Forms: <span>{forms.length}</span>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={forms}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isFormLoading}
          sx={{
            border: "none",
          }}
        />
        {isFormLoading ? <Loader /> : null}
      </Table>

      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
  position: relative;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  span {
    font-weight: bold;
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`;
