import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { TaskTable } from "./TaskTable";
import { colors } from "../../Shared/colors";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import "react-quill/dist/quill.snow.css";
import { FeedbackTable } from "../Leads/Search leads/FeedbackTable";

export const HierarchyDatatable = ({ tableData, name }) => {
  const [taskPopUp, setTaskPopUp] = useState(false);
  const [feedbackPopUp, setfeedbackPopUp] = useState(false);
  const [feedbackData, setfeedbackData] = useState([])
  const [leadId, setleadId] = useState("");

  const [pageSize, setPageSize] = useState(10);


  const columns = [
    {
      field: "clientName",
      headerName: "Client",
      width: 150,
    },
    {
      field: "leadId",
      headerName: "Lead ID",
      width: 150,
      renderCell: (params) => {
        return params.row.classification === "veryHot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryHot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "hot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.hot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "moderate" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.moderate}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "cold" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.cold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryCold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        );
      },
    },
    // {
    //   field: "cnic",
    //   headerName: "Cnic",
    //   width: 150,
    // },
    // {
    //   field: "clientOccupation",
    //   headerName: "Client Occupation",
    //   width: 150,
    // },
    // {
    //   field: "buyingIntent",
    //   headerName: "Buying Intent",
    //   width: 150,
    // },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "project",
      headerName: "Project",
      width: 150,
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 110,
      renderCell: (params) => {
        return (
          <>
            {
              params.row?.feedback?.length > 0 ?
                <Feedback>
                  <button onClick={() => {
                    setfeedbackPopUp(true)
                    setfeedbackData(params.row.feedback)
                  }}>View Feedback</button>
                </Feedback> : "No Feedback"
            }
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <Action>
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setleadId(params.row.id);
              }}
            />
          </Action>
        );
      },
    },
  ];
  const rows = [];
  tableData?.forEach((lead) => {
    rows.push({
      id: lead?._id,
      leadId: lead?.leadID,
      clientName: lead?.clientID?.fullName,
      buyingIntent: lead?.clientID?.buyingIntent,
      source: lead?.leadOpportunity?.source,
      phone: lead?.phoneNum,
      project: lead?.leadOpportunity?.project?.projectName,
      clientOccupation: lead?.clientID?.clientOccupation,
      feedback: lead?.feedback,
      classification: lead?.leadOpportunity?.classification,
    });
  });

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Leads ({name}):<span> {tableData?.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TaskTable leadId={leadId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={feedbackPopUp}>
        <PopUp>
          <Cross onClick={() => setfeedbackPopUp(!feedbackPopUp)} style={{ height: "3%" }}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <FeedbackTable feedback={feedbackData} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>


    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;

`;

const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${colors.textBlue};
  }
  p {
    color: ${colors.textMed};
  }
`;

const Feedback = styled.div`
button{
  background-color: ${colors.brown};
  color: ${colors.white};
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
`
