import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'
import Notifications from '../../Components/Leads/Notifications/Notifications'

export const NotificationsComponent = () => {
  return (
    <Main>
        <Notifications/>
    </Main>
  )
}

const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
padding-top: 2em;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`

