import React from 'react'
import { Puff } from 'react-loader-spinner'
import styled from 'styled-components'
import { colors } from './colors'
export const Loader = () => {
  return (
    <Main>
        <Puff
              height="80"
              width="80"
              radius={1}
              color={colors.brown}
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
    </Main>
  )
}

const Main = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: #00000030;
z-index: 12;
`
