import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import { colors } from "../../Shared/colors";
import {
  useDeleteClientMutation,
  useEditClientMutation,
  useGetAllClientsQuery,
} from "../../features/clientSlice";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Toaster, toast } from "react-hot-toast";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { decryptData } from "../../services/crypto";

export const ClientsDatatable = ({ clients, isAutoFetching, pageSize, setPageSize, setPage }) => {
  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

    if (storedStaffRole) {
      try {
        role = decryptData(storedStaffRole);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }
  
  const [numberPopUp, setnumberPopUp] = useState(false);

  const [clientId, setclientId] = useState(null);

  const [popUp, setpopUp] = useState(false);

  const [phoneData, setphoneData] = useState([]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
        setnumberPopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const [clientData, setclientData] = useState({
    firstName: "",
    newPhoneNo: "",
  });

  const [editClient, { isLoading }] = useEditClientMutation();
  const [deleteClient] = useDeleteClientMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setclientData({
      ...clientData,
      [name]: value,
    });
  };

  const handleDeleteClient = (id) => {
    deleteClient(id)
      .unwrap()
      .then((res) => {
        toast.success("Client deleted Successfully!");
      })
      .catch((err) => {
        toast.error("Error deleting client!");
      });
  };
  const handleClientSave = (e) => {
    e.preventDefault();
    let id = clientId;
    let body = {
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      newPhoneNo: clientData.newPhoneNo,
    };
    editClient({ id, body })
      .unwrap()
      .then((res) => {
        toast.success("Client Updated Successfully");
        setclientData({
          firstName: "",
          lastName: "",
          email: "",
          newPhoneNo: "",
        });
        setpopUp(false);
      })
      .catch((err) => {
        toast.error("Error updating Client");
      });
  };

  // const { data: clients, isFetching } = useGetAllClientsQuery({
  //   employeeId: staffId,
  //   page: page + 1,
  //   pageSize: pageSize,
  // })

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 120,
      renderCell: (params) => {
        return (
          <Allocated>
            <Tooltip title={params.row.client}>
              <div>{params.row.client}</div>
            </Tooltip>
          </Allocated>
        );
      },
    },
    {
      field: "allocatedto",
      headerName: "Allocated To",
      width: 150,
      renderCell: (params) => {
        return (
          <Allocated>
            <Tooltip title={params.row.allocatedto}>
              <div>{params.row.allocatedto}</div>
            </Tooltip>
          </Allocated>
        );
      },
    },
    {
      field: "rating",
      headerName: "Client Rating",
      width: 150,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title={params.row.email}>
    //         <div>{params.row.email}</div>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.row.phone.length > 1 ? (
              <button
                onClick={() => {
                  setphoneData(params.row.phone);
                  setnumberPopUp(true);
                }}
                style={{
                  width: "100%",
                  backgroundColor: `${colors.brown}`,
                  color: `${colors.white}`,
                  padding: "0.5em 0em",
                  cursor: "pointer",
                  borderRadius: "5px",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
              >
                {params.row.phone[0].phoneNo}
              </button>
            ) : (
              <a href={`tel:+${params.row?.phone[0]?.phoneNo}`}>{params.row?.phone[0]?.phoneNo}</a>
            )}
          </div>
        );
      },
    },
    {
      field: "buyingIntent",
      headerName: "Buying Intent",
      width: 150,
    },
    {
      field: "clientOccupation",
      headerName: "Client Occupation",
      width: 150,
    },
    {
      field: "clientType",
      headerName: "Client Type",
      width: 150,
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <Action>
            {role === "ceo" || role === "v3" ? (
              <DeleteIcon
                style={{ color: `${colors.hot}`, cursor: "pointer" }}
                onClick={() => handleDeleteClient(params.row.id)}
              />
            ) : null}
            <EditIcon
              style={{ color: `${colors.textBlue}`, cursor: "pointer" }}
              onClick={() => {
                setclientId(params?.row?.id);
                setclientData({
                  firstName: params.row?.client,
                  newPhoneNo: params.row?.phone[0]?.phoneNo,
                });
                setpopUp(!popUp);
              }}
            />
          </Action>
        );
      },
    },
  ];

  let rows = [];
  clients?.LoginEmployeeClients.forEach((client) => {
    rows.push({
      client: client.fullName,
      allocatedto: client.allocatedTo?.firstName,
      id: client?._id,
      rating: client?.clientRating,
      buyingIntent: client?.buyingIntent,
      clientOccupation: client?.clientOccupation,
      clientType: client?.clientType,
      country: client?.country,
      email: client?.email,
      phone: client?.cellNo,
      createdAt: client?.createdAt,
      updatedAt: client?.updatedAt,
    });
  });

  const exportCSV = () => {
    const headers = [
      "Client",
      "Allocated To",
      "Client Rating",
      "Phone Number",
      "Buying Intent",
      "Client Occupation",
      "Country",
      "Client Type"
    ];
    const rowsData = rows.map(row => [
      row.client,
      row.allocatedto,
      row.rating,
      row.phone[0].phoneNo,
      row.buyingIntent,
      row.clientOccupation,
      row.country,
      row.clientType
    ]);
    const csvContent = [
      headers.join(","),
      ...rowsData.map(row => row.join(","))
    ].join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Totalclients.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Main>
      <TimeSection>
        <button>All({clients?.totalClients})</button>
      </TimeSection>
      <LeadsSection>
        <div>
          Total Clients:<span> {clients?.totalClients} </span>
        </div>
        {role === "ceo" || role === "admin" || role === "account" ? (
          <button onClick={exportCSV}>
            <FileDownloadIcon />
            Export
          </button>
        ) : null}
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[20, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isAutoFetching}
          pagination
          paginationMode="server"
          // page={page}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowCount={clients?.totalClients || 0}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={numberPopUp}>
        <NumberPopUp>
          <Cross
            onClick={() => setnumberPopUp(!numberPopUp)}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <NumberPopUpSection style={{ height: "90%", overflowY: "scroll" }}>

            {phoneData.map((phone) => (
              <div>
                <span>Phone No: </span> <a href={`tel:+${phone.phoneNo}`}>{phone.phoneNo}</a>
              </div>
            ))}
          </NumberPopUpSection>
        </NumberPopUp>
      </PopUpContainer>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img
              src="/assets/icons/cross.png"
              alt="cross"
              style={{ height: "50%" }}
            />
          </Cross>
          <PopUpSection>
            <Section
              style={{
                width: "100%",
              }}
            >
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>First Name:</h3>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="type here..."
                      value={clientData?.firstName}
                      onChange={handleChange}
                    />
                  </InputFeild>
                  {/* <PhoneInputFeild>
                    <h3>New Phone Number:</h3>
                    <PhoneInputContainer>
                      <PhoneInput
                        inputProps={{ name: "newPhoneNo" }}
                        country="pk"
                        placeholder="Phone Number"
                        // value={clientData?.newPhoneNo}
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            newPhoneNo: e,
                          });
                        }}
                      />
                    </PhoneInputContainer>
                  </PhoneInputFeild> */}
                  <PhoneInputFeild>
                    <h3>New Phone Number:</h3>
                    <PhoneInputContainer>
                      <PhoneInput
                        inputProps={{ name: "newPhoneNo" }}
                        country="pk"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          if (e?.length <= 12) {
                            setclientData({
                              ...clientData,
                              newPhoneNo: e,
                            });
                          } else {
                            toast.error("Phone number must be 12 characters or less");
                          }
                        }}
                      />
                      {clientData.newPhoneNo?.length > 12 && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          Phone number must be 12 characters or less.
                        </p>
                      )}
                    </PhoneInputContainer>
                  </PhoneInputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                    marginLeft: "auto",
                    marginRight: "10%",
                  }}
                  type="submit"
                  disabled={isLoading}
                  onClick={handleClientSave}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 7em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
width: 100%;
display: flex;
justify-content: space-between;
span{
  font-weight: bold;
}
button{
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textMed};
  padding: 15px;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.8em,0.9vw,1em);
}`

const Table = styled.div`
  height: 80%;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  div {
    display: flex;
    align-items: center;
  }
`;

const Allocated = styled.div`
  div {
    :nth-child(2) {
      color: ${colors.textMed};
    }
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const NumberPopUp = styled.div`
  width: 30vw;
  height: 30vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const NumberPopUpSection = styled(PopUpSection)`
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  line-height: 3em;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;
const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const PhoneInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const PhoneInputContainer = styled.div`
  width: 100%;
  position: relative;
  .react-tel-input {
    .form-control {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid ${colors.inputBorderWhite};
      padding: 1em 0em 1em 3em;
      background-color: ${colors.inputWhite};
    }
    .flag-dropdown {
      border: 1px solid ${colors.inputBorderWhite};
      border-radius: 6px 0 0 6px;
    }
    .selected-flag,
    .selected-flag:hover {
      background: none;
    }
  }

  .css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    color: ${colors.inputFeild};
    cursor: pointer;
  }
  .cross {
    position: absolute;
    top: 50%;
    right: 13%;
    transform: translateY(-50%);
    color: ${colors.hot};
  }
`;

const PopUp = styled.div`
  width: 50%;
  height: 50%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  padding: 1em 2em;
  min-height: 30vh;
  height: 100%;
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  button {
    min-width: 8em;
    padding: 1em 2em;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;
