import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { AddNewLeadsThird } from "./AddNewLeadsThird";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useGetAllProjectsQuery } from "../../../features/projectSlice";
import { useGetApprovedAffiliateQuery } from "../../../features/affiliateSlice";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const AddNewLeadsSecond = ({ mainPhoneNumber, phoneData }) => {


  const options = useMemo(() => countryList().getData(), [])

  const [multiplePhone, setmultiplePhone] = useState([{phoneNo:mainPhoneNumber}]);

  const [phoneValue, setphoneValue] = useState('');

  const [popUp, setpopUp] = useState(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false)
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

 

  const [client, setclient] = useState({
    fullName: "",
    gender: "",
    fatherName: "",
    cnic: "",
    passportNo: "",
    email: "",
    landline: "",
    country: "",
    dob: "",
    phoneNo: multiplePhone,
    clientType: "",
    clientOccupation: "",
    clientRating: "",
    buyingIntent: "",
    affName: "",
  });


  // useEffect(() => {
  //   setclient(prevClient => ({
  //     ...prevClient,
  //     email: `${prevClient.fullName}@gmail.com`
  //   }));
  // }, [client.fullName]);
  
  useEffect(() => {
    if (phoneData) {
      setclient({
        fullName: phoneData.fullName,
        gender: phoneData.gender,
        fatherName: phoneData.fatherName,
        cnic: phoneData.cnic,
        passportNo: phoneData.passportNo,
        email: phoneData.email,
        landline: phoneData.landLineNumber,
        country: phoneData.country,
        dob: phoneData.dob,
        phoneNo: multiplePhone,
        clientType: phoneData.clientType,
        clientOccupation: phoneData.clientOccupation,
        clientRating: phoneData.clientRating,
        buyingIntent: phoneData.buyingIntent,
      });
    }
  }, [phoneData,multiplePhone]);


  const [lead, setlead] = useState({
    leadInterestedProjectID: "",
    classification: "",
    propertyType: "",
    subType: "",
    source: "",
    budgetPer: "",
    minPrice: 0,
    maxPrice: 0,
    landArea: "",
    beds: "",
    baths: "",
    constructionStatus: "",
    subject: "",
    leadmessage: "",
    leadcomment: "",
  });

  const resetClientAndLead = () => {
    setclient({
      fullName: "",
      gender: "",
      fatherName: "",
      cnic: "",
      passportNo: "",
      email: "",
      landline: "",
      country: "",
      dob: "",
      clientType: "",
      phoneNo: mainPhoneNumber,
      clientOccupation: "",
      clientRating: "",
      buyingIntent: "",
      affName: "",
    });

    setlead({
      leadInterestedProjectID: "",
      classification: "",
      propertyType: "",
      subType: "",
      source: "",
      budgetPer: "",
      minPrice: 0,
      maxPrice: 0,
      landArea: "",
      beds: "",
      baths: "",
      constructionStatus: "",
      subject: "",
      leadmessage: "",
      leadcomment: "",
    });
  };

  let { data: project } = useGetAllProjectsQuery();
  let {data:affiliate} = useGetApprovedAffiliateQuery();

  const affOptions = useMemo(() => {
    const updatedOptions = [];
    affiliate?.forEach((affiliate) => {
      updatedOptions.push({
        id: affiliate?._id,
        label: affiliate?.affName
      });
    });
    return updatedOptions;
  }, [affiliate]);

  const handleMultiplePhone = () => {
    if (phoneValue) {
      const updatedMultiplePhone = [...multiplePhone, { phoneNo: phoneValue }];
      setmultiplePhone(updatedMultiplePhone);
      setphoneValue("");
      setclient({ ...client, phoneNo: updatedMultiplePhone });
    }
  };

  const handleClientChange = (e) => {
    const { name, value } = e.target;
    let newValue = value
    if(name==='fullName'){
       newValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    setclient({
      ...client,
      [name]: newValue,
    });
  };

  const handleLeadChange = (e) => {
    const { name, value } = e.target;
    setlead({
      ...lead,
      [name]: value,
    });
  };

  const handleLandline = (e) => {
    setclient({
      ...client,
      landline: e,
    });
  };

  const handlePhoneValue = (e) => {
    setphoneValue(e);
  };

  const handleCountry = (e) =>{
    setclient({...client,country:e})
  }


  return (
    <>
      <Main>
        <Heading>Add Client Information</Heading>
        <InputSection>
          <Row>
            <RowPart1>
              <InputFeild>
                <h3>
                  Full Name: <span style={{ color: "red" }}>*</span>
                </h3>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  value={client.fullName}
                  onChange={handleClientChange}
                  placeholder="Type Name"
                />
              </InputFeild>
            </RowPart1>
            <RowPart2Pair>
              {/* <Pair> */}
                <InputFeild>
                  <h3>
                    Gender: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="gender"
                      id="gender"
                      onChange={handleClientChange}
                      value={client.gender}
                    >
                      <option value="" disabled>
                        Gender
                      </option>
                      <option value="male">male</option>
                      <option value="female">female</option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </InputFeild>
              {/* </Pair> */}
              {/* <Pair>
                <InputFeild>
                  <h3>S/O,D/O,W/O:</h3>
                  <input
                    type="text"
                    name="fatherName"
                    id="fatherName"
                    placeholder="Type Name"
                    value={client.fatherName}
                    onChange={handleClientChange}
                  />
                </InputFeild>
              </Pair> */}
            </RowPart2Pair>
          </Row>
          {/* <Row>
            <RowPart1>
              <InputFeild>
                <h3>Govt Id:</h3>
                <input
                  type="text"
                  name="cnic"
                  id="cnic"
                  placeholder=""
                  value={client.cnic}
                  onChange={handleClientChange}
                />
              </InputFeild>
            </RowPart1>
            <RowPart2>
              <InputFeild>
                <h3>Passport No:</h3>
                <input
                  type="text"
                  name="passportNo"
                  id="passportNo"
                  placeholder=""
                  value={client.passportNo}
                  onChange={handleClientChange}
                />
              </InputFeild>
            </RowPart2>
          </Row> */}

          {/* <Row>
            <RowPart1>
              <InputFeild>
                <h3>
                  E-mail Address:
                </h3>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Type Email"
                  value={client.email}
                  onChange={handleClientChange}
                  autocomplete="off"
                />
              </InputFeild>
            </RowPart1>
            <RowPart2Pair>
              <Pair>
                <PhoneInputFeild>
                  <h3>Landline:</h3>
                  <PhoneInputContainer>
                    <PhoneInput
                      inputProps={{ name: "landline", required: true }}
                      country="pk"
                      value={client.landline}
                      placeholder="landline"
                      onChange={handleLandline}
                    />
                  </PhoneInputContainer>
                </PhoneInputFeild>
              </Pair>
              <Pair>
                <CountryInputFeild>
                  <h3>Country:</h3>
                    <Select options={options} value={client.country} onChange={handleCountry} />
                </CountryInputFeild>
              </Pair>
            </RowPart2Pair>
          </Row> */}
          <Row>
            {/* <RowPart1>
              <InputFeild>
                <h3>Select DOB:</h3>
                <div>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    value={client.dob}
                    onChange={handleClientChange}
                    defaultValue={client.dob}
                  />
                </div>
              </InputFeild>
            </RowPart1> */}
            <RowPart2>
              <AffInputFeild>
                <h3>
                  Affiliate: <span style={{ color: "red" }}>*</span>
                </h3>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={affOptions}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(event, newvalue) =>{
                    setclient({ ...client, affName: newvalue.id })}
                  }
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    } else if (typeof option === 'object' && option !== null && option.hasOwnProperty('label')) {
                      return option.label;
                    } else if (typeof option === 'object' && option !== null && option.hasOwnProperty('id')) {
                      return option.id;
                    } else {
                      return '';
                    }
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    height:'2.9em',
                    overflow:'hidden',
                    border:`1px solid ${colors.inputBorderWhite}`
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: `${colors.inputWhite}`,height:'2.9em' }}
                    />
                  )}
                />
              </AffInputFeild>
            </RowPart2>
            <RowPart2Pair>
              <Pair>
                <PhoneInputFeild>
                  <h3>
                    Cell No: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <PhoneInputContainer>
                    <PhoneInput
                      inputProps={{ name: "cellno", required: true }}
                      country="pk"
                      value={phoneValue}
                      onChange={handlePhoneValue}
                      placeholder="Phone Number"
                    />
                  </PhoneInputContainer>
                </PhoneInputFeild>
              </Pair>
              <NumberPart>
                <Plus onClick={handleMultiplePhone}>
                  <img src="/assets/icons/plus.png" alt="plus" />
                </Plus>
                <Number onClick={() => setpopUp(true)}>
                  {mainPhoneNumber}
                </Number>
              </NumberPart>
            </RowPart2Pair>
          </Row>
          {/* <Row>
            <RowPart2Pair>
              <Pair>
                <PhoneInputFeild>
                  <h3>
                    Cell No: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <PhoneInputContainer>
                    <PhoneInput
                      inputProps={{ name: "cellno", required: true }}
                      country="pk"
                      value={phoneValue}
                      onChange={handlePhoneValue}
                      placeholder="Phone Number"
                    />
                  </PhoneInputContainer>
                </PhoneInputFeild>
              </Pair>
              <NumberPart>
                <Plus onClick={handleMultiplePhone}>
                  <img src="/assets/icons/plus.png" alt="plus" />
                </Plus>
                <Number onClick={() => setpopUp(true)}>
                  +{mainPhoneNumber}
                </Number>
              </NumberPart>
            </RowPart2Pair>
          </Row> */}
          <Row>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Client type: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="clientType"
                      id="clientType"
                      onChange={handleClientChange}
                      value={client.clientType}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="agent">Agent</option>
                      <option value="directclient">Direct Client</option>
                      <option value="investmentfund">Investment Fund</option>
                      <option value="investor">Investor</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Client Occupation: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="clientOccupation"
                      id="clientOccupation"
                      onChange={handleClientChange}
                      value={client.clientOccupation}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Jobian">Job</option>
                      <option value="businessman">Businessman</option>
                      <option value="socialworker">Social Worker</option>
                      <option value="enterpreneur">Enterpreneur</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Client Rating: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="clientRating"
                      id="clientRating"
                      onChange={handleClientChange}
                      value={client.clientRating}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="veryrich">Very Rich</option>
                      <option value="rich">Rich</option>
                      <option value="middleclass">Middle Class</option>
                      <option value="lowerclass">Lower Class</option>
                      <option value="poor">Poor</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Buying Intent: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="buyingIntent"
                      id="buyingIntent"
                      onChange={handleClientChange}
                      value={client.buyingIntent}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="1-3month">1-3 Month</option>
                      <option value="3-6month">3-7 Month</option>
                      <option value="6-9month">6-9 Month</option>
                      <option value="9-12month">9-12 Month</option>
                      <option value="morethanyear">More Than 1 Year</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair>
          </Row>
        </InputSection>
        <Heading>Lead/Opportunity</Heading>
        <InputSection>
          <Row>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Project: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="leadInterestedProjectID"
                      id="leadInterestedProjectID"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {project?.map((pro) => (
                        <option value={pro._id}>{pro.projectName}</option>
                      ))}
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>
                    Classification: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="classification"
                      id="classification"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="veryHot">Very Hot</option>
                      <option value="hot">Hot</option>
                      <option value="moderate">Moderate</option>
                      <option value="cold">Cold</option>
                      <option value="veryCold">Very Cold </option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair>
            <RowPart2Pair>
                <InputFeild>
                  <h3>
                    Source: <span style={{ color: "red" }}>*</span>
                  </h3>
                  <div>
                    <select
                      name="source"
                      id="source"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="walkIn">Walk In</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                      <option value="reference">Refrence</option>
                      <option value="billboards">Bill Board</option>
                    </select>
                  </div>
                </InputFeild>
            </RowPart2Pair>
            {/* <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>Property Type:</h3>
                  <div>
                    <select
                      name="propertyType"
                      id="propertyType"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="home">Home</option>
                      <option value="plot">Plot</option>
                      <option value="commercial">Commercial</option>
                      <option value="residential">Residential</option>
                      <option value="apartment">Apartment</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>Sub-Type:</h3>
                  <div>
                    <select
                      name="subType"
                      id="subType"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="duplexPlot">Duplex Plot</option>
                      <option value="residentalPlot">Residental Plot</option>
                      <option value="studioArgument">Studio Argument</option>
                      <option value="suite">Suite</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair> */}
          </Row>
          <Row>
           
          </Row>
        </InputSection>
        {/* <Heading>Lead Details</Heading>
        <InputSection>
          <Row>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>Budget Per:</h3>
                  <div>
                    <select
                      name="budgetPer"
                      id="budgetPer"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="squareMeter">Square Meter</option>
                      <option value="centiMeter">Centi Meter</option>
                      <option value="squareDecameter">Square Decameter</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <RowPart2Pair style={{ width: "100%" }}>
                  <Pair>
                    <InputFeild>
                      <h3>Min:</h3>
                      <input
                        type="number"
                        name="minPrice"
                        id="minPrice"
                        value={lead.minPrice}
                        onChange={handleLeadChange}
                      />
                    </InputFeild>
                  </Pair>
                  <Pair>
                    <InputFeild>
                      <h3>Max:</h3>
                      <input
                        type="number"
                        name="maxPrice"
                        id="maxPrice"
                        value={lead.maxPrice}
                        onChange={handleLeadChange}
                      />
                    </InputFeild>
                  </Pair>
                </RowPart2Pair>
              </Pair>
            </RowPart2Pair>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>Land Area:</h3>
                  <div>
                    <select
                      name="landArea"
                      id="landArea"
                      onChange={handleLeadChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="kanal">Kanal</option>
                      <option value="marla">Marla</option>
                      <option value="maraba">Maraba</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair>
          </Row>
          <Row>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>Beds:</h3>
                  <div>
                    <select name="beds" id="beds" onChange={handleLeadChange}>
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="1bed">1 Bed</option>
                      <option value="2bed">2 Bed</option>
                      <option value="3bed">3 Bed</option>
                      <option value="4bed">4 Bed</option>
                      <option value="5bed">5 Bed</option>
                      <option value="5OrMore">5 Or More Bed</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>Baths:</h3>
                  <div>
                    <select name="baths" id="baths" onChange={handleLeadChange}>
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="1bath">1 Bath</option>
                      <option value="2bath">2 Bath</option>
                      <option value="3bath">3 Bath</option>
                      <option value="4bath">4 Bath</option>
                      <option value="5bath">5 Bath</option>
                      <option value="5OrMore">5 Or More Bath</option>
                    </select>
                  </div>
                </InputFeild>
              </Pair>
            </RowPart2Pair>
            <RowPart2>
              <InputFeild>
                <h3>Construction Status:</h3>
                <div>
                  <select
                    name="constructionStatus"
                    id="constructionStatus"
                    onChange={handleLeadChange}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="underContruction">Under Construction</option>
                    <option value="halfCompleted">Half Completed</option>
                    <option value="moreThanHalfCompleted">
                      More Than Half Completed
                    </option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              </InputFeild>
            </RowPart2>
          </Row>
          <Row>
            <RowPart2Pair>
              <Pair>
                <InputFeild>
                  <h3>Subject:</h3>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    value={lead.subject}
                    onChange={handleLeadChange}
                  />
                </InputFeild>
              </Pair>
              <Pair>
                <InputFeild>
                  <h3>Message:</h3>
                  <input
                    type="text"
                    name="leadmessage"
                    id="leadmessage"
                    value={lead.leadmessage}
                    onChange={handleLeadChange}
                  />
                </InputFeild>
              </Pair>
            </RowPart2Pair>
            <RowPart2>
              <InputFeild>
                <h3>Comment:</h3>
                <input
                  type="text"
                  name="leadcomment"
                  id="leadcomment"
                  value={lead.leadcomment}
                  onChange={handleLeadChange}
                />
              </InputFeild>
            </RowPart2>
          </Row>
        </InputSection> */}
      </Main>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            {multiplePhone?.map((phone) => (
              <div>+{phone.phoneNo}</div>
            ))}
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <AddNewLeadsThird
        client={client}
        lead={lead}
        reset={resetClientAndLead}
      />
    </>
  );
};

const Main = styled.div`
  width: 90%;
  margin-top: 2em;
  background-color: ${colors.white};
  min-height: 60vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  font-size: clamp(1.2em, 1.2vw, 2.2em);
  width: 100%;
  margin-top: 1em;
`;
const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const RowPart1 = styled.div`
  width: 45%;
`;

const RowPart2 = styled.div`
  width: 45%;
`;

const RowPart2Pair = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
`;
const Pair = styled.div`
  width: 45%;
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const PhoneInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const AffInputFeild = styled(PhoneInputFeild)``

const PhoneInputContainer = styled.div`
  width: 100%;
  position: relative;
  .react-tel-input {
    .form-control {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid ${colors.inputBorderWhite};
      padding: 1em 0em 1em 3em;
      background-color: ${colors.inputWhite};
    }
    .flag-dropdown {
      border: 1px solid ${colors.inputBorderWhite};
      border-radius: 6px 0 0 6px;
    }
    .selected-flag,
    .selected-flag:hover {
      background: none;
    }
  }

  .css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    color: ${colors.inputFeild};
    cursor: pointer;
  }
  .cross {
    position: absolute;
    top: 50%;
    right: 13%;
    transform: translateY(-50%);
    color: ${colors.hot};
  }
`;
const NumberPart = styled.div`
  min-width: 50%;
  height: 50%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Plus = styled.div`
  width: 10%;
  height: 100%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Number = styled.div`
  color: ${colors.white};
  background-color: ${colors.brown};
  padding: 1em 2em;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 40%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.p`
  height: calc(100% - 10%);
  padding: 1em;
  line-height: 2em;
`;

const CountryInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  .css-13cymwt-control{
    border: 1px solid ${colors.inputBorderWhite};
    background-color: ${colors.inputWhite};

    padding: 0.4em 0em;
  }
  .css-t3ipsp-control{
    border: 1px solid ${colors.inputBorderWhite};
    background-color: ${colors.inputWhite};
    padding: 0.4em 0em;
  }
  
`