import React from 'react';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { colors } from '../../../Shared/colors';

const CsvBtn = () => {
  let data = [{
    phoneNumber: '123-456-7890',
    name: 'John Doe',
    clientType: 'Type A',
    clientOccupation: 'Engineer',
    clientRating: '5 stars',
    buyingIntent: 'High',
    classification: 'VIP',
    source: 'Referral',

  }]
  const handleDownload = () => {
    const csvHeader = [
      'PhoneNumber', 'Name', 'ClientType', 'ClientOccupation',
      'ClientRating', 'BuyingIntent', 'Classification', 'Source'
    ].join(',');

    const csvData = data.map(item => (
      [
        item.phoneNumber, item.name, item.email, item.clientType, item.clientOccupation,
        item.clientRating, item.buyingIntent, item.classification, item.source,
      ].join(',')
    )).join('\n');

    const csvBlob = new Blob([csvHeader, '\n', csvData], { type: 'text/csv' });
    saveAs(csvBlob, 'client_data.csv');
  };

  return (
    <Button onClick={handleDownload}>Download CSV Format Here</Button>
  );
};

export default CsvBtn;


const Button = styled.button`
width: 30%;
margin-top: 2em;
padding: 1em;
background-color: ${colors.brown};
color: ${colors.white};
border-radius: 5px;
border: none;
cursor: pointer;
`