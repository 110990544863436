import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./Routes/AppRoutes";
import { Toaster, toast } from "react-hot-toast";
import { broadcastAssignLeadMessage, broadcastPolicyMessage, meetingNotification, postgetNotify, setupSocket, subscribeToNotifications, unsubPostGetNotify, broadcastPaymentInitiateMessage, broadcastPaymentVerificationMessage, facebookLeadNotification, feedbackNotification } from "./services/socket";
import useSound from 'use-sound';
import { useNotification } from "./context/notficationContext";

function App() {
  const [Notification, setNotification] = useState("");
  const [playSound] = useSound('/assets/notification.mp3');
  const { toggleNotificationTrue } = useNotification()

  useEffect(() => {
    setupSocket()
  }, [])


  useEffect(() => {
    subscribeToNotifications((data) => {
      setNotification(data?.newMessage);
    });
  }, []);

  useEffect(() => {
    broadcastPolicyMessage((data) => {
      setNotification(data?.newMessage);
    });
  }, []
  )

  useEffect(() => {
    broadcastAssignLeadMessage((data) => {
      setNotification(data?.newMessage);
    });
  }, [])

  useEffect(() => {
    broadcastPaymentInitiateMessage((data) => {
      setNotification(data?.newMessage);
    });
  }, [])
  useEffect(() => {
    broadcastPaymentVerificationMessage((data) => {
      setNotification(data?.newMessage);
    });
  }, [])

  useEffect(() => {
    meetingNotification((data) => {
      setNotification(data?.newMessage);
    })
  }, [])

  useEffect(() => {
    facebookLeadNotification((data) => {
      setNotification(data?.newMessage);
    })
  }, [])
  useEffect(() => {
    feedbackNotification((data) => {
      setNotification(data?.newMessage);
    })
  }, [])



  useEffect(() => {
    if (Notification) {
      playSound()
      toast.success(Notification, {
        duration: 15000,
      })
      setNotification('')
    }
  }, [Notification])


  useEffect(() => {
    if (Notification) {
      toggleNotificationTrue()
    }
  }, [Notification])


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppRoutes />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
}

export default App;