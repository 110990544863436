import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { AddNewLeadsSecond } from "./AddNewLeadsSecond";
import SearchIcon from "@mui/icons-material/Search";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSearchClientQuery } from "../../../features/clientSlice";
import { Puff } from "react-loader-spinner";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { toast, Toaster } from "react-hot-toast";
import Papa from "papaparse";
import {
  useAddClientLeadTaskMutation,
  useAddClinetLeadCSVMutation,
} from "../../../features/taskSlice";
import { useGetAllProjectsQuery } from "../../../features/projectSlice";
import CsvBtn from "./CsvBtn";
import { duplicateEmployee } from "../../../services/socket";
import CryptoJS from "crypto-js";
import { decryptData } from "../../../services/crypto";


export const AddNewLeadsFirst = () => {
  const [phone, setphone] = useState("92");
  const [isUploading, setIsUploading] = useState(false);
  const [skip, setskip] = useState(true);

  const [projectId, setprojectId] = useState("");

  let { data: project } = useGetAllProjectsQuery();
  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  let empName = useMemo(() => {
    let staffName = null;
    let storedStaffName = localStorage.getItem("staffName");

    if (storedStaffName) {
      try {
        staffName = decryptData(storedStaffName);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }

    return staffName;
  }, []);

  let loginEmpNum = useMemo(() => {
    // let data = JSON.parse(localStorage.getItem("user"));
    let user = null;
    const storedData = localStorage.getItem("user");

    if (storedData) {
      try {
        user = decryptData(storedData);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }


    return user?.phoneNum;
  }, []);

  const handlePhone = (e) => {
    setphone(e);
    if (skip === false) {
      setskip(true);
    }
  };
  const handleReset = () => {
    setphone("92");
    if (skip === false) {
      setskip(true);
    }
  };

  let {
    data: phoneData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useSearchClientQuery(phone, {
    skip,
  });

  const isDuplicateEmployeeCalled = useRef(false);

  useEffect(() => {
    if (error && role !== "ceo") {
      let data = {
        empId: error?.data?.empId,
        type: "private",
        message: `${empName} (${loginEmpNum}) tried to add your existing lead with the number ${phone}`,
      };
      if (!isDuplicateEmployeeCalled.current) {
        duplicateEmployee(data.empId, data.type, data.message);
        isDuplicateEmployeeCalled.current = true;
      }
    } else {
      isDuplicateEmployeeCalled.current = false;
    }
  }, [error]);

  let searchNumber = (e) => {
    setskip(false);
  };

  let handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchNumber();
    } else if (e.key === "Escape") {
      handleReset();
    }
  };

  const [addClinetLeadCSV] = useAddClinetLeadCSVMutation();

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploading(true); 
      const formData = new FormData();
      formData.append("projectId", projectId);
      formData.append("file", file);

      try {
        const res = await addClinetLeadCSV(formData).unwrap();
        const { message, addedCount, duplicateCount, alreadyExistClient } = res;

        toast.success(
          <div>
            <h4>{message}</h4>
            <p>
              New Clients Added: <strong>{addedCount}</strong>
            </p>
            <p>Already Existing Clients:</p>
            <ul style={{ maxHeight: "100px", overflowY: "scroll" }}>
              {Array.isArray(alreadyExistClient) &&
                alreadyExistClient.slice(0, 5).map((phone, index) => (
                  <li key={index}>📞 {phone}</li>
                ))}
            </ul>
            {Array.isArray(alreadyExistClient) && alreadyExistClient.length > 5 && (
              <p>...and {alreadyExistClient.length - 5} more duplicates</p>
            )}
          </div>,
          {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "custom-toast",
          }
        );
      } catch (err) {
        toast.error(err?.data?.error?.message || "Something went Wrong");
      } finally {
        setIsUploading(false); 
      }
    }
    e.target.value = ""; 
  };

  return (
    <>
      <Main>
        <Heading>Add Client/Lead</Heading>
        <InputSection>
          <InputPart>
            <h3>Cell No:</h3>
            <Input>
              <PhoneInput
                inputProps={{ name: "phone", required: true }}
                country="pk"
                value={phone}
                placeholder="Phone Number"
                onChange={handlePhone}
                onKeyDown={handleKeyDown}
              />
              <SearchIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "2%",
                  transform: "translateY(-50%)",
                  color: `${colors.inputFeild}`,
                  cursor: "pointer",
                }}
                onClick={searchNumber}
              />
              <img
                src="/assets/icons/cross.png"
                alt="cross"
                className="cross"
                onClick={handleReset}
              />
            </Input>
          </InputPart>
          {phone.length <= 2 ? (
            <InputPart>
              <Or>OR</Or>
              <CsvBtn />
              <InputProjectFeild>
                <h3>
                  Project: <span style={{ color: "red" }}>*</span>
                </h3>
                <div>
                  <select
                    name="leadInterestedProjectID"
                    id="leadInterestedProjectID"
                    onChange={(e) => setprojectId(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    {project?.map((pro) => (
                      <option value={pro._id}>{pro.projectName}</option>
                    ))}
                  </select>
                </div>
              </InputProjectFeild>
              <InputFeild>
                <h3>
                  Attachment:{" "}
                  <b style={{ color: "red", fontSize: "0.8em" }}>
                    maximum allowed rows (1000)
                  </b>
                </h3>
                {isUploading ? (
                  <Loader>
                    <Puff
                      height="50"
                      width="50"
                      radius={1}
                      color={colors.brown}
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </Loader>
                ) : (
                  <span>
                    <p>Upload file here</p>
                    <label htmlFor="profilePic">
                      <AttachFileIcon /> Upload
                    </label>
                    <input
                      type="file"
                      name="profilePic"
                      id="profilePic"
                      onChange={(e) => handleFileUpload(e)}
                    />
                  </span>
                )}
              </InputFeild>
            </InputPart>
          ) : null}
        </InputSection>
        {isLoading ? (
          <Loader>
            <Puff
              height="50"
              width="50"
              radius={1}
              color={colors.brown}
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Loader>
        ) : isSuccess ? (
          <AddNewLeadsSecond mainPhoneNumber={phone} phoneData={phoneData} />
        ) : isError ? (
          <div style={{ color: `${colors.hot}`, marginTop: "1em" }}>
            {error?.data?.message}
          </div>
        ) : null}
      </Main>
    </>
  );
};

const Main = styled.div`
  background-color: ${colors.inputWhite};
  width: 90%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const Heading = styled.div`
  font-size: clamp(1.2em, 1.2vw, 2.2em);
`;

const InputSection = styled.div`
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const InputPart = styled.div``;

const Input = styled.div`
  width: 30%;
  position: relative;
  .react-tel-input {
    .form-control {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid ${colors.inputBorderWhite};
      padding: 1em 0em 1em 3em;
    }
    .flag-dropdown {
      border: 1px solid ${colors.inputBorderWhite};
      border-radius: 6px 0 0 6px;
      background-color: ${colors.white};
    }
    .selected-flag,
    .selected-flag:hover {
      background: none;
    }
  }
  .cross {
    position: absolute;
    top: 50%;
    right: 13%;
    transform: translateY(-50%);
    color: ${colors.hot};
    cursor: pointer;
  }
`;

const Loader = styled.div`
  margin-top: 1em;
`;

const InputFeild = styled.div`
  width: 30%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select,
    input[type="date"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.white};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const InputProjectFeild = styled.div`
  width: 30%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select,
    input[type="date"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Or = styled.div`
  margin-top: 2em;
  color: ${colors.hot};
  width: 30%;
  text-align: center;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    left: 0;
    width: 45%;
    height: 0.1em;
    background-color: ${colors.inputBorderWhite};
  }
  ::after {
    content: "";
    position: absolute;
    right: 0;
    width: 45%;
    height: 0.1em;
    background-color: ${colors.inputBorderWhite};
  }
`;
