import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'
import { AddNewLeadsFirst } from '../../Components/Leads/Add new leads/AddNewLeadsFirst'

export const AddNewLeadsComponent = () => {
  return (
    <Main>
        <AddNewLeadsFirst/>
    </Main>
  )
}

const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
padding-top: 2em;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`