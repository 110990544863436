import React from 'react'
import styled from 'styled-components'
import { ClientsDatatable } from '../Components/Clients/ClientsDatatable'
import { ClientsFirst } from '../Components/Clients/ClientsFirst'
import { colors } from '../Shared/colors'

export const ClientsComponent = () => {
  return (
    <Main>
        <ClientsFirst/>
        {/* <ClientsDatatable/> */}
    </Main>
  )
}


const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
margin-top: 10vh;
display: flex;
padding-top: 2em;
flex-direction: column;
justify-content: center;
align-items: center;
`