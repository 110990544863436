import React from 'react'
import styled from 'styled-components'
import { TodoListDataTable } from '../../Components/Leads/TodoList/TodoListDataTable'
import { TodoListFirst } from '../../Components/Leads/TodoList/TodoListFirst'
import { colors } from '../../Shared/colors'

export const TodoList = () => {
  return (
    <Main>
        <TodoListFirst/>
        <TodoListDataTable/>
    </Main>
  )
}

const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
padding-top: 2em;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`

