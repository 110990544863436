import { apiSlice } from "./api/apiSlice";


const targetSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        addtarget:builder.mutation({
            query:(target)=>({
                url:'target/create',
                method:'POST',
                body:target
            }),
            invalidatesTags:["Target"]
        }),
        getAlltargets:builder.query({
            query:(id)=>({
                url:`target/single/emp/${id}`
            }),
            transformResponse:(response,meta,arg)=>response,
            providesTags:['Target']
            
        })
    })
})


export const {useAddtargetMutation,useGetAlltargetsQuery} = targetSlice;