import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../Shared/colors'

export const TodoListFirst = () => {
  return (
    <Main>
        <Search>
            <Heading>
                ToDo List
            </Heading>
            <SubHeading>
                Task to do
            </SubHeading>
            <SearchSection1>
                <InputSection>
                <h3>Task Type:</h3>
                <div>
                <select name="taskType" id="taskType">
                    <option value="">Select</option>
                </select>
                </div>
                </InputSection>
                <InputSection>
                <h3>Show Task:</h3>
                <div>
                <select name="showTask" id="showTask">
                    <option value="">Select</option>
                </select>
                </div>
                </InputSection>
                <InputSection>
                <h3>Deadline:</h3>
                <div>
                <select name="deadLine" id="deadLine">
                    <option value="">17-12-2022</option>
                </select>
                </div>
                </InputSection>
            </SearchSection1>
            <SearchSection2>
                <InputSection>
                <h3>Allocated To:</h3>
                <input type="text" name="allocatedTo" id="allocatedTo" placeholder='khizar'/>
                </InputSection>
                <Button>
                    Search
                </Button>
            </SearchSection2>
        </Search>
    </Main>
  )
}


const Main = styled.div`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
min-height: 30vh;
`

const Search = styled.div`
background-color: ${colors.inputWhite};
width: 100%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 1em;
`

const Heading = styled.h4`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1em,1.3vw,2em);
padding-bottom: 1em;
`

const SubHeading = styled.h4`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1em,1vw,1.5em);
border-bottom: 1px solid ${colors.inputBorderWhite};
padding-bottom: 1em;
`

const SearchSection1 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
`

const SearchSection2 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
align-items: flex-end;
`


const InputSection = styled.div`
min-width: 2em;
width: 20vw;
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.white};
  select{
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
}
input{
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
`

const Button = styled.button`
padding: 1em 2em;
color: ${colors.white};
background-color: ${colors.brown};
border: none;
border-radius: 5px;
cursor: pointer;
`