import React, { useState ,useMemo,useCallback} from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../Shared/colors";
import styled from "styled-components";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OverdueTaskTable } from "./OverdueTaskTable";


export const OverdueTable = ({overdueTasks,name}) => {

  const [pageSize, setPageSize] = useState(20);
  const [taskId, setTaskId] = useState("");
  const [taskPopUp, setTaskPopUp] = useState(false);


  const columns = [
    {
      field: "task",
      headerName: "Task",
      width: 150,
    },
    {
      field: "deadline",
      headerName: "DeadLine",
      width: 150,
      renderCell: (params) => {
        let date = params.row.deadline;
        return <div>{date.toDateString()}</div>;
      },
    },
    {
      field: "client",
      headerName: "Client",
      width: 130,
      renderCell: (params) => {
        return (
          <Client>
            <Tooltip title={params.row.client}>
              <div>{params.row.client}</div>
            </Tooltip>
            {/* <div>
                <StarIcon sx={
                  {
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`
                  }}/>
              <span>1 lead</span>
            </div> */}
          </Client>
        );
      },
    },
    // {
    //   field: "rating",
    //   headerName: "Rating",
    //   width: 150,
    // },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => {
        return <div>{params.row.phone}</div>;
      },
    },
    // {
    //   field:'allocatedto',
    //   headerName:'Allocated To',
    //   width:130,
    //   renderCell:(params)=>{
    //     return(
    //       <Allocated>
    //         <div>
    //           {params.row.allocatedto}
    //         </div>
    //         {/* <div>
    //           faisalabad
    //         </div> */}
    //       </Allocated>
    //     )
    //   }
    // },
    {
      field: "classification",
      headerName: "Classification",
      width: 100,
      renderCell: (params) => {
        return params.row.classification === "veryHot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryHot}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "hot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.hot}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "moderate" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.moderate}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "cold" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.cold}`,
                }}
              />
            </div>
          </Id>
        ) : (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryCold}`,
                }}
              />
            </div>
          </Id>
        );
      },
    },
    {
      field: "project",
      headerName: "Project",
      width: 110,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.project}>
            <div style={{ color: `${colors.textBlue}` }}>
              {params.row.project}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 180,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <Action>
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setTaskId(params.row.leadID);
              }}
            />
          </Action>
        );
      },
    },
  ];


  let rows = useMemo(() => {
    let rows = []
    overdueTasks?.forEach(task => {
        rows.push({
            id: task._id,
            task: task?.nextTask?.nextTaskType,
            deadline: new Date(task?.nextTask?.deadline),
            client: task.client.fullName,
            rating: task?.client?.clientRating,
            project: task?.leadID?.leadOpportunity?.project?.projectName,
            classification: task?.leadID?.leadOpportunity?.classification,
            cellNo: task?.client.cellNo[0]?.phoneNo,
            mail: task?.client.email,
            phone: task?.client?.cellNo[0].phoneNo,
            leadID:task?.leadID?._id
          });
    });
    return rows
  }, [overdueTasks])
  return (
    <Main>
        <LeadsSection>
        <div>
          Overdue Tasks ({name}):<span> {overdueTasks?.length} </span>
        </div>
      </LeadsSection>
      <Table>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[20, 50, 100]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      sx={{
        border: "none",
      }}
    />
    </Table>
    <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <OverdueTaskTable taskId={taskId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  );
};
const Main = styled.div`
  width: 100%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${colors.textBlue};
  }
  p {
    color: ${colors.textMed};
  }
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;


const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;