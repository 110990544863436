import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  useReadAllMutation,
} from "../../features/notificationSlice";
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import { colors } from "../../Shared/colors";


const NotificationModel = ({ notifications, onClose }) => {
  const [readAll, { isLoading: readLoading }] = useReadAllMutation();

  const markAllAsRead = () => {
    readAll()
      .unwrap()
      .then((res) => {
        toast.success("Marked all as read!");
      })
      .catch((err) => {
        toast.error("unable to mark all notifications as read!");
      });
  };
  return (
    <NotificationContainer>
      <Header>
        <Title>Notifications</Title>
        <MarkAllButton onClick={markAllAsRead}>
          {readLoading ? "Processing..." : "Mark All as Read"}
        </MarkAllButton>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Header>
      <Body>
        {notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <Item key={index} isRead={notification.isRead}>
              <Text>{notification.message}</Text>
              <TimeText>{moment(new Date(notification?.createdAt)).fromNow(true)} ago</TimeText>
            </Item>
          ))
        ) : (
          <EmptyMessage>No new notifications</EmptyMessage>
        )}
      </Body>
    </NotificationContainer>
  );
};

export default NotificationModel;

// Styled Components
const slideIn = keyframes`
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;  
  max-height: 500px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: auto;
  z-index: 1000;
  transform: translate(-50%, -50%);
  animation: ${slideIn} 0.5s ease-out;

   &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.brown};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #249393;
  color: #fff;
  border-radius: 10px 10px 0 0;
`;

const MarkAllButton = styled.button`
  min-width: 10em;
  padding: 0.5em 1em;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
  color: #249393; 
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #1a6f6f;
    color: #ffffff;
  }

  &:active {
    background-color: #155c5c;
  }
`;
const CloseButton = styled.button`
  min-width: 6em;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
  color: #249393;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: red;
    color: white;
  }

  &:active {
    color: darkred;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.2em;
`;

const Body = styled.div`
  padding: 20px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${(props) => (props.isRead ? "#f8f9fa" : "#249393")};
  color: ${(props) => (props.isRead ? "black" : "white")};
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid ${(props) => (props.isRead ? "#249393" : "white")}; /* Dynamically set border color */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Text = styled.p`
  flex: 1; 
  margin: 0;
  font-size: 0.9em;
  overflow-wrap: break-word;
`;

const TimeText = styled.p`
  margin: 0;
  font-size: 0.7em;
  font-weight: 800;
  flex-shrink: 0; 
  white-space: nowrap;
  margin-left: 10px;
`;

const EmptyMessage = styled.p`
  text-align: center;
  color: #777;
  font-size: 0.9em;
  margin-top: 20px;
`;
