import { apiSlice } from "./api/apiSlice";

const socialMediaSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllActiveForms: builder.query({
      query: () => ({
        url: `facebook/leadgen_forms`,
      }),
      transformResponse: (response, meta, arg) => response.data,
    //   providesTags: ["Social"],
    }),
  }),
});

export const {
    useGetAllActiveFormsQuery,
} = socialMediaSlice;
