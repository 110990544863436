import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import StarIcon from "@mui/icons-material/Star";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Toaster, toast } from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import Tooltip from "@mui/material/Tooltip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../Shared/colors";
import { useAddNewTaskLeadIdMutation } from "../../features/taskSlice";
import { useGetAllProjectsQuery, useGetSingleProjectQuery } from "../../features/projectSlice";
import { useLazyGetInventoryByProjectIdQuery } from "../../features/InventorySlice";
import { useAddFavouriteMutation } from "../../features/employeeSlice";
import { TaskTable } from "./TaskTable";
import { Banks } from "../../Shared/Banks";
import { Loader } from "../../Shared/Loader";
import moment from 'moment-timezone';


export const AffiliateLeadsDataTable = ({ leads }) => {
  const [pageSize, setPageSize] = useState(20);
  const [popUp, setpopUp] = useState(false);
  const [leadId, setleadId] = useState("");

  const [subType, setsubType] = useState();

  const [tokenDownPayment, settokenDownPayment] = useState(false);

  const [loading, setloading] = useState(false);

  const [closedLost, setclosedLost] = useState(false);

  const [addTask, { isLoading }] = useAddNewTaskLeadIdMutation()

  const [taskPopUp, setTaskPopUp] = useState(false);

  const [numberPopUp, setnumberPopUp] = useState(false);

  const [phoneData, setphoneData] = useState([]);

  const [doNothing, setdoNothing] = useState(false);

  const [maxDate, setmaxDate] = useState("");

  const [minDate, setminDate] = useState("");

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
        setTaskPopUp(false);
        setnumberPopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const today = moment();
  const defaultDate = today.format('YYYY-MM-DDTHH:mm');
  const newMinDate = today.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm');

  useEffect(() => {
    setminDate(newMinDate);
  }, [newMinDate]);

  useEffect(() => {
    setmaxDate(defaultDate);
  }, [defaultDate]);

  const [task, settask] = useState({
    currentTaskType: "",
    currentSubTypeTask: "",
    completedDate: defaultDate,
    reason: "",
    comment: "",
    nextTaskType: "",
    deadline: "",
    DProjectID: "",
    DTprojectBlockName: "",
    invenID: "",
    ammount: "",
    paymentMode: "",
    bankName: "",
    transactionNo: "",
    recieptNo: "",
    depositeDate: "",
    status: "",
    attachment: "",
    approvedPayment: "",
    leadId: "",
    // currentTaskType,
    //   currentSubTypeTask,
    //   completedDate,
    //   reason,
    //   comment,
    //   nextTaskType,
    //   deadline,
    //   DTprojectBlockName,
    //   //Sales Attribute
    //   DDProjectID,
    //   DTDTprojectBlockName,
    //   invenID,
    //   ammount,
    //   paymentMode,
    //   bankName,
    //   transactionNo,
    //   recieptNo,
    //   depositeDate,
    //   status,
  });

  let { data: project } = useGetAllProjectsQuery()

  let { data: singleProject } = useGetSingleProjectQuery(task.DProjectID);

  const [trigger, { data: inventory }] = useLazyGetInventoryByProjectIdQuery()

  useEffect(() => {
    if (singleProject) {
      setloading(false);
    }
  }, [singleProject]);

  useEffect(() => {
    if (task.DProjectID || task.DTprojectBlockName) {
      let project = task.DProjectID;
      let block = task.DTprojectBlockName;
      trigger({ project, block });
    }
  }, [task.DProjectID, task.DTprojectBlockName, trigger]);

  let inventoryData = [];
  inventory?.forEach((inven) => {
    if (inven.status === "Available") {
      inventoryData.push({
        unitNo: inven.unitNo,
        id: inven._id,
      });
    }
  });

  const handleProject = (e) => {
    let { name, value } = e.target;
    setloading(true);
    settask({ ...task, DTprojectBlockName: "", [name]: value });
  };

  let handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "nextTaskType") {
      setdoNothing(false);
      if (value === "doNothing") {
        setdoNothing(true);
      }
    }
    settask({ ...task, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    settask((prevstate) => ({
      ...prevstate,
      [name]: e.target.files[0],
    }));

    e.target.files = "";
  };

  const handleType = (e) => {
    if (e.target.value === "Call") {
      setsubType([
        "SMS",
        "Call connected",
        "Call Not Connected",
        "Whatsapp",
        "Followed Up",
      ]);
    } else if (e.target.value === "Meeting") {
      setsubType([
        "Vedio Meeting",
        "Head Office Meeting",
        "Outdoor",
        "Site",
        "Walk In",
      ]);
    } else if (e.target.value === "Sales") {
      setsubType([
        "Token Payment",
        "CLosed (Won)",
        "Closed (Lost)",
        "Complete Down Payment",
      ]);
    }
    handleChange(e);
  };

  const handleSubtype = (e) => {
    settokenDownPayment(false);
    setclosedLost(false);
    if (
      e.target.value === "Token Payment" ||
      e.target.value === "Complete Down Payment"
    ) {
      settokenDownPayment(true);
    }
    if (e.target.value === "Closed (Lost)") {
      setclosedLost(true);
    }
    handleChange(e);
  };

  const handleSave = () => {
    var formdata = new FormData();

    const completedDate = moment(task.completedDate).utc().format('YYYY-MM-DDTHH:mm:ss');

    formdata.append("currentTaskType", task.currentTaskType);
    formdata.append("currentSubTypeTask", task.currentSubTypeTask);
    formdata.append("completedDate", completedDate);
    formdata.append("reason", task.reason);
    formdata.append("comment", task.comment);
    formdata.append("nextTaskType", task.nextTaskType);
    formdata.append("deadline", task.deadline);
    formdata.append("attachment", task.attachment);
    formdata.append("DProjectID", task.DProjectID);
    formdata.append("DTprojectBlockName", task.DTprojectBlockName);
    formdata.append("invenID", task.invenID);
    formdata.append("ammount", task.ammount);
    formdata.append("paymentMode", task.paymentMode);
    formdata.append("bankName", task.bankName);
    formdata.append("transactionNo", task.transactionNo);
    formdata.append("recieptNo", task.recieptNo);
    formdata.append("depositeDate", task.depositeDate);
    formdata.append("status", task.status);
    formdata.append("approvedPayment", task.approvedPayment);
    let id = task.leadId;
    addTask({ formdata, id })
      .unwrap()
      .then((res) => {
        toast.success("task created successfully");
        settask({
          currentTaskType: "",
          currentSubTypeTask: "",
          completedDate: defaultDate,
          reason: "",
          comment: "",
          nextTaskType: "",
          deadline: "",
          DProjectID: "",
          DTprojectBlockName: "",
          invenID: "",
          ammount: "",
          paymentMode: "",
          bankName: "",
          transactionNo: "",
          recieptNo: "",
          depositeDate: "",
          status: "",
          attachment: "",
          approvedPayment: "",
          leadId: task.leadId,
        });
        setpopUp(false);
      })
      .catch((error) => {
        toast.error(error?.data?.error?.message);
      });
  };

  const [favouriteLoading, setfavouriteLoading] = useState(false);


  const [addFavourite] = useAddFavouriteMutation()

  const handleAddFavourite = (id) => {
    setfavouriteLoading(true);
    const body = {
      LeadID: id,
    };
    addFavourite(body)
      .unwrap()
      .then((res) => {
        toast.success("Added to favourite");
        setfavouriteLoading(false);
      })
      .catch((error) => {
        toast.error(error.data.message);
        setfavouriteLoading(false);
      });
  };
  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 110,
      renderCell: (params) => {
        return (
          <Client>
            <Tooltip title={params.row.client}>
              <div>{params.row.client}</div>
            </Tooltip>
            <div>
              {params.row.favourite ? (
                <StarIcon
                  sx={{
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`,
                  }}
                />
              ) : (
                <StarBorderIcon
                  sx={{
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`,
                  }}
                  onClick={() => handleAddFavourite(params.row.id)}
                />
              )}
              <span>lead</span>
            </div>
          </Client>
        );
      },
    },
    // {
    //   field: "allocatedto",
    //   headerName: "Allocated To",
    //   width: 110,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title={params.row.allocatedto}>
    //         <div>{params.row.allocatedto}</div>
    //         {/* <div>faisalabad</div> */}
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "leadId",
      headerName: "Lead ID",
      width: 150,
      renderCell: (params) => {
        return params.row.classification === "veryHot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryHot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "hot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.hot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "moderate" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.moderate}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "cold" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.cold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryCold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        );
      },
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.row.phone.length > 1 ? (
              <button
                onClick={() => {
                  setphoneData(params.row.phone);
                  setnumberPopUp(true);
                }}
                style={{
                  width: "100%",
                  backgroundColor: `${colors.brown}`,
                  color: `${colors.white}`,
                  padding: "0.5em 0em",
                  cursor: "pointer",
                  borderRadius: "5px",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
              >
                {params.row.phone[0].phoneNo}
              </button>
            ) : (
              <>{params.row.phone[0].phoneNo}</>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    //   renderCell: (params) => {
    //     return params.row.status === "contracted" ? (
    //       <Contracted color={"#66cb66d6"}>
    //         <div>{params.row.status}</div>
    //       </Contracted>
    //     ) : params.row.status === "E-mail" ? (
    //       <Contracted color={"#6767f4"}>
    //         <div>{params.row.status}</div>
    //       </Contracted>
    //     ) : (
    //       <Contracted color={"#f85a5a"}>
    //         <div>{params.row.status}</div>
    //       </Contracted>
    //     );
    //   },
    // },
    {
      field: "project",
      headerName: "Project",
      width: 110,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.project}>
            <div style={{ color: `${colors.textBlue}` }}>
              {params.row.project}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 180,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        let whatsappLink = `https://wa.me/` + params.row.cellNo;
        let mailLink = `mailto:` + params.row.mail;
        return (
          <Action>
            <div
              onClick={() => {
                settask({ ...task, leadId: params.row.id });
                setpopUp(true);
              }}
            >
              <img src="/assets/icons/plus.png" alt="plus" />
            </div>
            <a
              href={whatsappLink}
              onClick="location.href=this.href+'?key='+number;return false;"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon style={{ color: "green", cursor: "pointer" }} />
            </a>
            <a href={mailLink} target="_blank" rel="noreferrer">
              <MailOutlineIcon
                style={{ color: "#1194D1", cursor: "pointer" }}
              />
            </a>
            <ShareIcon style={{ color: "#EC2643", cursor: "pointer" }} />
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setleadId(params.row.id);
              }}
            />
          </Action>
        );
      },
    },
  ];
  let rows = [];
  leads?.forEach((lead) => {
    rows.push({
      client: lead?.clientID?.fullName,
      allocatedto: lead?.employeeID?.firstName,
      leadId: lead?.leadID,
      source: lead?.leadOpportunity?.source,
      project: lead?.leadOpportunity?.project?.projectName,
      classification: lead?.leadOpportunity?.classification,
      favourite: lead?.favourite,
      id: lead._id,
      clientId: lead.clientID._id,
      cellNo: lead?.clientID.cellNo[0]?.phoneNo,
      mail: lead?.clientID.email,
      phone: lead?.clientID?.cellNo,
    });
  });

  return (
    <Main>
      <LeadsSection>
        Affiliate Leads:<span> {leads?.length} </span>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            border: "none",
          }}
        />
        {favouriteLoading ? <Loader /> : null}
      </Table>
      {/* <PopUpContainer  popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            <TaskTable leadId={leadId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer> */}

      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            <Section>
              <Task>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Current Task</h3>
                  <InputFeild>
                    <h3>Type:</h3>
                    <div>
                      <select
                        name="currentTaskType"
                        id="currentTaskType"
                        value={task.currentTaskType}
                        onChange={handleType}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Call">Call</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Sales">Sales</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Sub-Type:</h3>
                    <div>
                      <select
                        name="currentSubTypeTask"
                        id="currentSubTypeTask"
                        value={task.currentSubTypeTask}
                        onChange={handleSubtype}
                      >
                        <option value="">Select</option>
                        {subType?.map((ls) => (
                          <option value={ls} key={ls}>
                            {ls}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Completed Date:</h3>
                    <div>
                      <input
                        type="datetime-local"
                        name="completedDate"
                        id="completedDate"
                        value={task.completedDate || defaultDate}
                        onChange={handleChange}
                        min={newMinDate}
                        max={defaultDate}
                      />
                    </div>
                  </InputFeild>
                  {closedLost ? (
                    <InputFeild>
                      <h3>Reason:</h3>
                      <input
                        type="text"
                        name="reason"
                        id="reason"
                        placeholder="Type Here..."
                        value={task.comment}
                        onChange={handleChange}
                      />
                    </InputFeild>
                  ) : null}
                  {tokenDownPayment === false ? (
                    <>
                      <InputFeild>
                        <h3>Comment:</h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                      {
                        task.currentTaskType === 'Meeting' || task.currentTaskType === "Sales" || task.currentSubTypeTask === "Whatsapp" ?
                          <InputFeild>
                            <h3>Attachment:</h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="attachment">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.attachment && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.attachment)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="attachment"
                              id="attachment"
                              onChange={handleFileChange}
                            />
                          </InputFeild> : null}
                    </>
                  ) : null}
                  {tokenDownPayment ? (
                    <>
                      <InputFeild>
                        <h3>Project:</h3>
                        <div>
                          <select
                            name="DProjectID"
                            id="DProjectID"
                            onChange={handleProject}
                          >
                            <option value="">Select Project</option>
                            {project?.map((pro) => (
                              <option value={pro._id}>{pro.projectName}</option>
                            ))}
                          </select>
                        </div>
                      </InputFeild>
                      {loading ? (
                        <TaskLoader>
                          <Puff
                            height="40"
                            width="40"
                            radius={1}
                            color={colors.brown}
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </TaskLoader>
                      ) : null}
                      {singleProject?.projectBlock?.length > 0 ? (
                        <InputFeild>
                          <h3>Project Block:</h3>
                          <div>
                            <select
                              name="DTprojectBlockName"
                              id="DTprojectBlockName"
                              onChange={handleChange}
                            >
                              <option value="">select</option>
                              {singleProject?.projectBlock?.map((pro) => (
                                <option value={pro.blockName}>
                                  {pro.blockName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </InputFeild>
                      ) : null}
                      <InputFeild>
                        <h3>Unit:</h3>
                        <div>
                          <select
                            name="invenID"
                            id="invenID"
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {inventoryData?.map((inven) => (
                              <option value={inven.id}>{inven.unitNo}</option>
                            ))}
                          </select>
                        </div>
                      </InputFeild>
                      <Heading>Add Payment</Heading>
                      <InputFeild>
                        <h3>Amount:</h3>
                        <input
                          type="text"
                          name="ammount"
                          id="ammount"
                          value={task.ammount}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Payment Mode:</h3>
                        <div>
                          <select
                            name="paymentMode"
                            id="paymentMode"
                            onChange={handleChange}
                            value={task.paymentMode}
                          >
                            <option value="">Select</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                          </select>
                        </div>
                      </InputFeild>
                      <BankInputFeild>
                        <h3>
                          Bank Name: <b style={{ color: "red" }}>*</b>
                        </h3>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={Banks}
                          value={task.bankName}
                          onChange={(event, newvalue) =>
                            settask({ ...task, bankName: newvalue })
                          }
                          sx={{
                            width: "100%",
                            border: `1px solid ${colors.inputBorderWhite}`,
                            borderRadius: "5px",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ backgroundColor: `${colors.inputWhite}` }}
                            />
                          )}
                        />
                      </BankInputFeild>
                      <InputFeild>
                        <h3>Transaction No:</h3>
                        <input
                          type="text"
                          name="transactionNo"
                          id="transactionNo"
                          value={task.transactionNo}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Reciept No:</h3>
                        <input
                          type="text"
                          name="recieptNo"
                          id="recieptNo"
                          value={task.recieptNo}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Deposite Date</h3>
                        <div>
                          <input
                            type="date"
                            name="depositeDate"
                            id="depositeDate"
                            value={task.depositeDate}
                            onChange={handleChange}
                          />
                        </div>
                      </InputFeild>
                      <InputFeild>
                        <h3>Attach Payment Proof:</h3>
                        <span>
                          <p>upload file here</p>
                          <label htmlFor="approvedPayment">
                            <AttachFileIcon /> Upload
                          </label>
                          {task.approvedPayment && (
                            <UploadedFile
                              src={URL.createObjectURL(task.approvedPayment)}
                              alt="uploaded file"
                            />
                          )}
                        </span>
                        <input
                          type="file"
                          name="approvedPayment"
                          id="approvedPayment"
                          onChange={handleFileChange}
                        />
                        <p>
                          Note: Invalid payment proof will result in
                          cancellation of payment and inventory will released
                          immediately.
                        </p>
                      </InputFeild>
                      <InputFeild>
                        <h3>Comment:</h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                      <FixedContainer>
                        <Price>
                          <h4>Unit Price:</h4>
                          <p>PKR 1230000</p>
                        </Price>
                        <InputFeild>
                          <h3>Unit Status:</h3>
                          <div>
                            <select
                              name="status"
                              id="status"
                              value={task.status}
                              onChange={handleChange}
                            >
                              <option value="downPayment" disabled>
                                downPayment
                              </option>
                            </select>
                          </div>
                        </InputFeild>
                        <InputFeild>
                          <h3>Unit Discount</h3>
                          <input
                            type="text"
                            value={"0"}
                            name="uniDiscount"
                            id="unitDiscount"
                            disabled
                          />
                        </InputFeild>
                        <InputFeild>
                          <h3>Deal Price</h3>
                          <input
                            type="text"
                            value={"342324"}
                            name="dealPrice"
                            id="dealPrice"
                            disabled
                          />
                        </InputFeild>
                        <Price>
                          <h4>Due Amount:</h4>
                          <p>PKR 1230000</p>
                        </Price>
                      </FixedContainer>
                    </>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    settask({
                      currentTaskType: "",
                      currentSubTypeTask: "",
                      completedDate: defaultDate,
                      reason: "",
                      comment: "",
                      nextTaskType: "",
                      deadline: "",
                      DProjectID: "",
                      DTprojectBlockName: "",
                      invenID: "",
                      ammount: "",
                      paymentMode: "",
                      bankName: "",
                      transactionNo: "",
                      recieptNo: "",
                      depositeDate: "",
                      status: "",
                      attachment: "",
                      approvedPayment: "",
                      leadId: task.leadId,
                    });
                    setpopUp(false);
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task style={{ borderRight: "none" }}>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Next Task</h3>
                  <InputFeild>
                    <h3>Next Task:</h3>
                    <div>
                      <select
                        name="nextTaskType"
                        id="nextTaskType"
                        value={task.nextTaskType}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="doNothing">Do Nothing</option>
                        <option value="followUp">Follow Up</option>
                        <option value="contractedClient">
                          Contracted Client
                        </option>
                        <option value="meetClient">Meet Client</option>
                        <option value="recievePartialDownPayment">
                          Recieve Partial Down Payment
                        </option>
                        <option value="recieveCompleteDownPayment">
                          Recieve Complete Down Payment
                        </option>
                        <option value="arrangeMeeting">Arrange Meeting</option>
                        <option value="receivetokenPayment">
                          Receive Token Payment
                        </option>
                      </select>
                    </div>
                  </InputFeild>
                  {doNothing === false ? (
                    <InputFeild>
                      <h3>Deadline:</h3>
                      <div>
                        <input
                          type="datetime-local"
                          name="deadline"
                          id="deadline"
                          value={task.deadline}
                          onChange={handleChange}
                          min={new Date().toISOString().slice(0, 16)}
                        />
                      </div>
                    </InputFeild>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TaskTable leadId={leadId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={numberPopUp}>
        <NumberPopUp>
          <Cross
            onClick={() => setnumberPopUp(!numberPopUp)}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <NumberPopUpSection style={{ height: "90%" }}>
            {phoneData.map((phone) => (
              <div>+{phone.phoneNo}</div>
            ))}
          </NumberPopUpSection>
        </NumberPopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
  position: relative;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    overflow: hidden;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  span {
    font-weight: bold;
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`;

const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${colors.textBlue};
  }
  p {
    color: ${colors.textMed};
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;
const NumberPopUp = styled.div`
  width: 30vw;
  height: 40vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const NumberPopUpSection = styled(PopUpSection)`
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  line-height: 3em;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  border-bottom: 1px solid ${colors.inputFeild};
  border-right: 1px solid ${colors.inputFeild};
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;

const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  color: ${colors.textBlue};
  font-size: clamp(1.5em, 2vh, 3em);
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const FixedContainer = styled.div`
  width: 100%;
  min-height: 3em;
  margin-top: 1em;
  background-color: ${colors.inputBorderWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const Price = styled.div`
  display: flex;
  p {
    margin-left: 2em;
  }
  margin-top: 1em;
`;

const TaskLoader = styled.div`
  margin-top: 1em;
`;

const BankInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 50%;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
