import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { colors } from '../../Shared/colors';
import EditIcon from '@mui/icons-material/Edit';



export const TrainingDataTable = () => {
  const [pageSize, setPageSize] = useState(5);

  const columns = [
    {
        field: 'assessmentName',
        headerName: 'Assessment Name',
        width: 150,
    },
    {
        field: 'noOfUsers',
        headerName: 'No of Users',
        width: 150,
    }
    ,
    {
      field:'dateCreated',
      headerName:'Date Created',
      width:110,
    }
    ,
    {
      field:'timeSchedule',
      headerName:'Time Schedule',
      width:110,
    }
    ,
    {
      field:'expiry',
      headerName:'Expiry',
      width:110,
    }
    ,
    {
      field:'status',
      headerName:'Status',
      width:110,
    },
    {
      field:'averageScore',
      headerName:'Average Score',
      width:110,
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 80,
      sortable: false ,
      filterable:false,
      hideable:false,
      renderCell:()=>{
        return(
          <Action>
            <EditIcon style={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
          </Action>
        )
      }
    },
  ];


const rows = [
];

  return (
    <Main>
      <LeadsSection>
        <div>

      Training | Assesments
        </div>
      </LeadsSection>
    <TimeSection>
        <button>
          Total Assessments: 63
        </button>
        <button>
          Completed Assessments: 63
        </button>
        <button>
          Pending Assessments: 63
        </button>
    </TimeSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 1em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`
const TimeSection = styled.div`
height: 8%;
display: flex;
align-items: flex-end;
button{
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover{
        background-color: ${colors.brown};
        color: ${colors.white};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
width: 100%;
display: flex;

border-bottom: 1px solid ${colors.inputBorderWhite};
justify-content: space-between;
span{
  font-weight: bold;
}
button{
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textMed};
  padding: 1em;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.8em,0.9vw,1em);
}
`

const Table = styled.div`
height: 80%;
`




const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`