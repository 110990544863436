import React from "react";
import styled from "styled-components";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { colors } from "../../../Shared/colors";
import { ClassificationLoading } from "../../../Shared/ClassificationLoading";
import { useGetClassificationQuery, useGetDashboardReportQuery } from "../../../features/employeeSlice";

export const Classification = ({staff}) => {
  // const { data: classification} = useGetClassificationQuery();
  
  const { data: classification } = useGetDashboardReportQuery({staffId: staff?.id});

  const COLORS = [];

  classification?.leadClassificationReport?.map((element)=>(
    COLORS.push(colors[element._id])
  ))

  return (
    <Main>
      <p>Classification</p>
      <Chart>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={1000} height={1000}>
            <Pie
              data={classification?.leadClassificationReport}
              cx={100}
              cy={100}
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={2}
              dataKey="count"
            >
              {classification?.leadClassificationReport?.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Progress>
          {classification?.leadClassificationReport?.map((ls) => (
            <ClassificationLoading data={ls} />
          ))}
        </Progress>
      </Chart>
    </Main>
  );
};

const Main = styled.div`
  width: 40%;
  min-width: 30em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: ${colors.inputWhite};
  margin-top: 3em;
  padding: 1em;
  overflow: hidden;
  p {
    font-size: clamp(1em, 3vh, 4em);
    margin-bottom: 1em;
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

const Progress = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
