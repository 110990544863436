import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LayersIcon from "@mui/icons-material/Layers";
import { useGetTokensQuery } from "../../features/accountSlice";
import {
  useGetMeetingsQuery,
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../features/employeeSlice";
import { useGetAllNotificationsQuery } from "../../features/notificationSlice";
import { Autocomplete, TextField } from "@mui/material";

import NotificationModel from "../notificationModel/NotificationModel";
import { decryptData } from "../../services/crypto";

export const DashboardFirst = ({ staff, setStaff, showModal, setShowModal }) => {
  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) { 
      console.error("Failed to decrypt data:", error);
    }
  }

  const { data: notificationsData, isLoading, error } = useGetAllNotificationsQuery();
  const [notificationMessage, setNotificationMessages] = useState('');
  const [showModalLocal, setShowModalLocal] = useState(showModal);

  useEffect(() => {
    if (notificationsData && Array.isArray(notificationsData) && notificationsData.length > 0) {
      const hasShownModal = localStorage.getItem("notificationShown");
      if (!hasShownModal || hasShownModal === "false") {
        setNotificationMessages([...notificationsData]);
        setShowModalLocal(true);
        localStorage.setItem("notificationShown", "true");
      }
    }
  }, [notificationsData]);

  const {
    data: tokens,
    isLoading: isTokensLoading,
    error: tokensError,
  } = useGetTokensQuery();
  const {
    data: meetings,
    isLoading: isMeetingsLoading,
    error: meetingsError,
  } = useGetMeetingsQuery();

 
  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  if (!staffId) {
    console.error("Staff ID not found in localStorage");
  }

  const {
    data: hierarchyStaff,
    isLoading: isHierarchyLoading,
    error: hierarchyError,
  } = useGetSingleHierarchyQuery({ staffId });
  const { data: allStaff } = useGetStaffQuery();

  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const allStaffOptions = useMemo(() => {
    let options = [];
    allStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [allStaff]);

  const selectedStaff =
    staffOptions.find((option) => option.id === staff?.id) || null;

  const handleCloseModal = () => {
    setShowModalLocal(false);
  };

  if (hierarchyError || meetingsError) {
    return <div>Error loading data</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return <div>Error loading notifications</div>; 
  }

  return (
    <Main>
      <InputPart>
        <StaffInputField>
          <h3>Staff:</h3>
          <Autocomplete
            id="staff-autocomplete"
            size="small"
            options={
              role === "ceo" || role === "admin" || role === "account"
                ? allStaffOptions
                : staffOptions
            }
            getOptionLabel={(option) => option.label}
            style={{
              width: "100%",
              borderRadius: "5px",
              minHeight: "2.9em",
              marginLeft: "0.8em",
              overflow: "hidden",
              border: `1px solid ${colors.inputBorderWhite}`,
            }}
            onChange={(e, value) => {
              setStaff(value || null);
            }}
            clearOnEscape
            value={selectedStaff}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  backgroundColor: `${colors.white}`,
                  paddingTop: "1em",
                }}
              />
            )}
          />
        </StaffInputField>
      </InputPart>
      <CardPart>
        <CardSection>
          <CardIcon>
            <CalendarMonthIcon />
          </CardIcon>
          <h3>Meetings</h3>
          <h2>{meetings?.countMeeting || 0}</h2>
        </CardSection>
        <CardSection>
          <CardIcon>
            <LayersIcon />
          </CardIcon>
          <h3>Tokens</h3>
          <h2>{tokens?.length || 0}</h2>
        </CardSection>
      </CardPart>
      {showModalLocal && (
        <>
          <Overlay onClick={handleCloseModal} />
          <NotificationModel
            notifications={notificationMessage}
            onClose={handleCloseModal}
          />
        </>
      )}
    </Main>
  );
};
const Main = styled.div`
  background-color: ${colors.white};
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 3em;
  padding-top: 1rem;
`;

const InputPart = styled.div`
  width: 42%;
`;

const CardPart = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const CardSection = styled.div`
  width: 45%;
  background-color: ${colors.inputWhite};
  border-radius: 5px;
  border: 1px solid ${colors.inputBorderWhite};
  min-height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  h3 {
    color: ${colors.brown};
    font-size: clamp(1em, 3vh, 2em);
  }
  h4 {
    font-size: clamp(1em, 3vh, 2em);
  }
`;

const CardIcon = styled.div`
  width: 15%;
  height: 2em;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  .css-i4bv87-MuiSvgIcon-root {
    color: ${colors.brown};
  }
`;

const StaffInputField = styled.div`
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em 1em 1em;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px); 
  z-index: 999;
`;
