import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";

const unauthorizedAccess = (store) => {
  let isUnauthorized = false;
  return (next) => (action) => {

    if (action.error && action.payload?.status === 401 && !isUnauthorized) {
        sessionStorage.setItem('unauthorized','true')
        isUnauthorized=true;
    }
    return next(action)
  }
}
export const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]:apiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
      .concat(unauthorizedAccess),
})