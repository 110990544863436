import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ClientsComponent } from "../Pages/ClientsComponent";
import { DashboardComponent } from "../Pages/DashboardComponent";
import { AddNewLeadsComponent } from "../Pages/Leads/AddNewLeadsComponent";
import { CalenderComponent } from "../Pages/Leads/CalenderComponent";
import { ManageLeadsComponent } from "../Pages/Leads/ManageLeadsComponent";
import { SearchLeadsComponent } from "../Pages/Leads/SearchLeadsComponent";
import {NotificationsComponent} from '../Pages/Leads/NotificationsComponent'
import { TodoList } from "../Pages/Leads/TodoList";
import { LoginComponent } from "../Pages/LoginComponent";
import { StaffComponent } from "../Pages/Staff/StaffComponent";
import { MalkiyetContactComponent } from "../Pages/Staff/MalkiyetContactComponent";
import { Layout } from "./Layout";
import { AffiliateComponent } from "../Pages/AffiliateComponent";
import { InventoryComponent } from "../Pages/Inventory/InventoryComponent";
// import { AccountsComponent } from '../Pages/Accounts/AccountsComponent';
import { TargetComponent } from "../Pages/TargetComponent";
import { PoliciesComponent } from "../Pages/PoliciesComponent";
import { Unauthorized } from "./Unauthorized";
import { NotFound } from "./NotFound";
import { RequireAuth } from "./RequireAuth";
import { ReportsComponent } from "../Pages/ReportsComponent";
import { TrainingComponent } from "../Pages/TrainingComponent";
import { DocumentationComponent } from "../Pages/DocumentationComponent";
import { TokenPaymentComponent } from "../Pages/Accounts/TokenPaymentComponent";
import { DownPaymentComponent } from "../Pages/Accounts/DownPaymentComponent";
import { PartialDownPaymentComponent } from "../Pages/Accounts/PartialDownPaymentComponent";
import { ChangePasswordComponent } from "../Pages/ChangePassword";
import { ProjectsComponent } from "../Pages/Inventory/ProjectsComponent";
import { TeamHerarchy } from "../Pages/Staff/TeamHerarchy";
import { ManageTeamComponent } from "../Pages/Staff/ManageTeamComponent";
import { FavouriteLeadsComponent } from "../Pages/Leads/FavouriteLeadsComponent";
import { SharedLeadsComponent } from "../Pages/Leads/SharedLeadsComponent";
import { ActiveFromComponent } from "../Pages/SMM/ActiveFormComponent";

export const AppRoutes = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<LoginComponent setShowModal={setShowModal} />} />
        <Route path="changePassword/:token" element={<ChangePasswordComponent />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
                "ts"
              ]}
            />
          }
        >
          <Route index element={<DashboardComponent showModal={showModal} setShowModal={setShowModal}/>} />
        </Route>

        <Route
          path="leads"
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
                "ts"
              ]}
            />
          }
        >
          <Route index element={<SearchLeadsComponent />} />
          <Route path="favourite" element={<FavouriteLeadsComponent/>} />
          <Route path="shared" element={<SharedLeadsComponent/>} />
          <Route path="todolist" element={<TodoList />} />
          <Route path="addnewleads" element={<AddNewLeadsComponent />} />
          <Route path="manageleads" element={<ManageLeadsComponent />} />
          <Route path="calendar" element={<CalenderComponent />} />
          <Route path="notifications" element={<NotificationsComponent/>} />
        </Route>

        <Route
          path="social"
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",

              ]}
            />
          }
        >
          <Route index element={<SearchLeadsComponent />} />
          <Route path="forms" element={<ActiveFromComponent/>} />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
                "ts"
              ]}
            />
          }
        >
          <Route path="clients" element={<ClientsComponent />} />
        </Route>

        <Route
          path="staff"
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "admin",
              ]}
            />
          }
        >
          <Route index element={<MalkiyetContactComponent/>} />
          <Route
            path="manageStaff"
            element={<StaffComponent/>}
          />
          <Route
            path="manageTeamHerarchy"
            element={<TeamHerarchy/>}
          />
          <Route
            path="manageTeam"
            element={<ManageTeamComponent/>}
          />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
              ]}
            />
          }
        >
        <Route path="affiliate" element={<AffiliateComponent />} />
        </Route>
        <Route
          path='inventory'
            element={
              <RequireAuth
                allowedRoles={[
                  "ceo",
                  "dos",
                  "doa",
                  "account",
                  "admin",
                  "dm",
                  "rsm",
                  "sm",
                  "manger",
                  "sbdm",
                  "bdm",
                  "tl",
                  "bda",
                  "am",
                  "dcc",
                  "tse",
                  "developer",
                  "v3",
                  "hr",
                  "aps",
                  "tse",

                ]}
              />
            }
          >
          <Route index element={<InventoryComponent />} />
          <Route path="projects" element={<ProjectsComponent/>} />
        </Route>
        <Route
          path="accounts"
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",

              ]}
            />
          }
        >
          {/* <Route index element={<AccountsComponent/>}/> */}
          <Route index element={<TokenPaymentComponent />} />
          <Route path="downPayment" element={<DownPaymentComponent />} />
          <Route
            path="partialDownPayment"
            element={<PartialDownPaymentComponent />}
          />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",

              ]}
            />
          }
        >
          <Route path="targets" element={<TargetComponent />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
                "ts"

              ]}
            />
          }
        >
          <Route path="policies" element={<PoliciesComponent />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",

              ]}
            />
          }
        >
          <Route path="reports" element={<ReportsComponent />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",

              ]}
            />
          }
        >
          <Route path="training" element={<TrainingComponent />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                "ceo",
                "dos",
                "doa",
                "account",
                "admin",
                "dm",
                "rsm",
                "sm",
                "manger",
                "sbdm",
                "bdm",
                "tl",
                "bda",
                "am",
                "dcc",
                "tse",
                "developer",
                "v3",
                "hr",
                "aps",
                "tse",
                "ts"

              ]}
            />
          }
        >
          <Route path="documentation" element={<DocumentationComponent />} />
        </Route>

        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/notfound" />} />
      </Route>
    </Routes>
  );
};
