import React from 'react'
import styled from 'styled-components'
import { ReportFirst } from '../Components/Reports/ReportFirst'
import { ReportsDatatable } from '../Components/Reports/ReportsDatatable'
import { ReportsSecond } from '../Components/Reports/ReportsSecond'
import { SelectedStaffProvider } from '../context/selectedStaff'
import { colors } from '../Shared/colors'

export const ReportsComponent = () => {
  return (
    <Main>
      <SelectedStaffProvider>
        <ReportFirst />
      </SelectedStaffProvider>
    </Main>
  )
}


const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
margin-top: 10vh;
display: flex;
padding-top: 2em;
flex-direction: column;
justify-content: center;
align-items: center;
`