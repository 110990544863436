import React from 'react'
import {Outlet, useMatch} from 'react-router-dom'
import styled from 'styled-components'
import { Header } from '../Components/Header/Header'
import { Sidebar } from '../Components/Sidebar/Sidebar'
export const Layout = () => {
  let login = useMatch('/login')
  let change = useMatch('/changePassword/:token')
  let dashboard = useMatch('/')
  let match = false;
  if(login || dashboard || change) {
    match = true;
  }
  return (
    <Main>
    <Sidebar/>
    <Container match={match}>
      <Header/>
      <OutletSection match={match}>
      <Outlet/>
      </OutletSection>
    </Container>
    </Main>
  )
}

const Main = styled.div`
display: flex;
flex-direction: column;
overflow: hidden;
`
const Container = styled.div`

`

const OutletSection = styled.div`
width: ${props=>props.match?'100vw':'calc(100vw - 20vw)'};
margin-left: ${props=>props.match?'none':'20vw'};
margin-top: ${props=>props.match?'none':'10vh'};
`