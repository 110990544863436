import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import { NavLink, useMatch } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TaskIcon from "@mui/icons-material/Task";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StarRateIcon from "@mui/icons-material/StarRate";
import ShareIcon from "@mui/icons-material/Share";
import { subscribeToNotifications } from "../../services/socket";
import CryptoJS from "crypto-js";
import { decryptData } from "../../services/crypto";


export const LeadsSidebar = () => {

  // let user = JSON.parse(localStorage.getItem("user"));

  let user = null;
  const storedData = localStorage.getItem("user");
  
  if (storedData) {
    try {
      user = decryptData(storedData);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  return (
    <>
    <MainContainer>
      <Section>
        <p>Leads</p>
        <div>
          <NavLink end to="leads">
            <SearchOutlinedIcon /> <span>Search Leads</span>
          </NavLink>
          <NavLink end to="leads/favourite">
            <StarRateIcon />
            <span>Favourite Leads</span>
          </NavLink>
          <NavLink end to="leads/shared">
            <ShareIcon />
            <span>Shared Leads</span>
          </NavLink>
          <NavLink end to="leads/todolist">
            <TaskIcon />
            <span>Todo List</span>
          </NavLink>
          <NavLink end to="leads/addnewleads">
            <ControlPointIcon />
            <span>Add New Leads</span>
          </NavLink>
          <NavLink end to="leads/manageleads">
            <SettingsBackupRestoreIcon />
            <span>Manage Leads</span>
          </NavLink>
          <NavLink end to="leads/calendar">
            <CalendarMonthIcon />
            <span>Calendar</span>
          </NavLink>
        </div>
      </Section>
      <Section>
        <p>Others</p>
        <div>
          <NavLink end to="leads/notifications">
             <NotificationsIcon />
            <span>Notifications</span>
          </NavLink>
        </div>
      </Section>
       </MainContainer>
    </>
  );
};


const MainContainer = styled.div`
  height: 100%; 
  overflow-y: auto; 
  scrollbar-width: none;
 -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.div`
display: flex;
flex-direction: column;
p{
    color: ${colors.textLight};
    font-size: clamp(0.9em,0.9vh,2em);
    margin-top: 1em;
}
div{
    display: flex;
    flex-direction: column;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: ${colors.textMed};
        width: 100%;
        padding: 1em 0;
        font-size: clamp(1em,1vh,2em);
        margin-top: 1em;
        border-radius: 5px;
        transition: all 0.3s ease-out;
        position: relative;
        ::before{
            content: '';
            position: absolute;
            left: -48px;
            width: 20px;
            height: 100%;
            background-color: ${colors.brown};
            border-radius: 5px;
            display: none;
        }
        :hover{
            background-color: ${colors.inputWhite};
            color: ${colors.brown};
            ::before{
                display: block;
            }
        }
        
        span{
            width: 50%;
            margin-left: 5%;
        }
    }
    .active{
            background-color: ${colors.inputWhite};
            color: ${colors.brown};
            ::before{
                display: block;
            }
        }
}
`

// const MainContainer = styled.div`
//   height: 100%; 
//   overflow-y: auto; 
//   scrollbar-width: none;
//  -ms-overflow-style: none;

//   ::-webkit-scrollbar {
//     display: none;
//   }
 
// `;


// const Section = styled.div`
// display: flex;
// flex-direction: column;
// p{
//     color: ${colors.textLight};
//     font-size: clamp(0.9em,0.9vh,2em);
//     margin-top: 1em;
// }
// div{
//     display: flex;
//     flex-direction: column;
//     a {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   color: ${colors.textMed};
//   width: 100%;
//   padding: 1em 0;
//   font-size: clamp(1em, 1vh, 2em);
//   margin-top: 1em;
//   border-radius: 5px;
//   transition: all 0.3s ease-out;
//   position: relative;

//   /* Add pseudo-element for the left border */
//   ::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     height: 100%;
//     width: 5px; /* Width of the left border */
//     background-color: ${colors.brown}; /* Left border color */
//     border-radius: 5px 0 0 5px; /* Smooth rounded edge on the left */
//     display: none; /* Hidden by default */
//   }

//   :hover {
//     background-color: ${colors.inputWhite};
//     color: ${colors.brown};

//     /* Show the left border on hover */
//     ::before {
//       display: block;
//     }
//   }

//   &.active {
//     background-color: ${colors.inputWhite};
//     color: ${colors.brown};

//     /* Show the left border for active state */
//     ::before {
//       display: block;
//     }
//   }
// }
//         span{
//             width: 50%;
//             margin-left: 5%;
//         }
//     }
//     .active{
//             background-color: ${colors.inputWhite};
//             color: ${colors.brown};
//             ::before{
//                 display: block;
//             }
//         }
// }
// `