import React from "react";
import styled from "styled-components";
import { TokenPaymentDataTable } from "../../Components/Accounts/tokenPayment/tokenPaymentDatatable";
import { colors } from "../../Shared/colors";
export const TokenPaymentComponent = () => {
  return (
    <Main>
      <TokenPaymentDataTable />
    </Main>
  );
};

const Main = styled.div`
  background-color: ${colors.inputWhite};
  min-height: calc(100vh - 10vh);
  margin-top: 10vh;
  display: flex;
  padding-top: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
