import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { decryptData } from '../services/crypto';

export const RequireAuth = ({ allowedRoles }) => {

  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  const location = useLocation();
  return (
    allowedRoles?.includes(role)
      ?
      <Outlet /> :
      role ?
        <Navigate to='/unauthorized' state={{ from: location }} replace />
        : <Navigate to='/login' state={{ from: location }} replace />
  )
}
