import { apiSlice } from "./api/apiSlice";


const profileSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProfileCardData: builder.query({
            query: (id) => ({
                url: `dashboard/profile-data`
            }),
            providesTags: ['Profile']

        })
    })
})

export const { useGetProfileCardDataQuery } = profileSlice;