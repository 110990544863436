import React from 'react'
import { AffiliateFirst } from '../Components/Affiliate/AffiliateFirst'
import styled from 'styled-components'
import { colors } from '../Shared/colors'
import { AffiliateDataTable } from '../Components/Affiliate/AffiliateDataTable'

export const AffiliateComponent = () => {
  return (
    <Main>
      <AffiliateFirst/>
      <AffiliateDataTable/>
    </Main>
  )
}


const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
margin-top: 10vh;
display: flex;
padding-top: 2em;
flex-direction: column;
justify-content: center;
align-items: center;
`