import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../Shared/colors'
import { CallsReport } from './CallsReport'
import { Classification } from './Classification'
import DownloadPdf from './DownloadPdf'
import { LeadsReport } from './LeadsReport'
import { PendingTask } from './PendingTask'
import { QuarterlyLead } from './QuarterlyLead'

export const DashboardSecond = ({staff, setStaff}) => {
  return (
    <Main>
        <LeadsReport staff={staff}/>
        <PendingTask staff={staff}/>
        {/* <QuarterlyLead/> */}
        <CallsReport staff={staff}/>
        <Classification staff={staff}/>
        <DownloadPdf/>
    </Main>
  )
}


const Main = styled.div`
width: 90%;
background-color: ${colors.white};
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
align-items: center;
margin-bottom: 3em;
padding-bottom: 3em;
`