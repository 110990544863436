import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { DataGrid } from "@mui/x-data-grid";
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useGetTodoListTaskQuery } from "../../../features/leadsSlice";
import { Toaster, toast } from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
} from "../../../features/projectSlice";
import { useLazyGetInventoryByProjectIdQuery } from "../../../features/InventorySlice";
import { useAddNewTaskMutation } from "../../../features/taskSlice";
import { TaskTable } from "./TaskTable";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Banks } from "../../../Shared/Banks";
import moment from 'moment-timezone';

export const TodoListDataTable = () => {
  const [subType, setsubType] = useState();

  const [tokenDownPayment, settokenDownPayment] = useState(false);

  const [loading, setloading] = useState(false);

  const [closedLost, setclosedLost] = useState(false);

  const [addTask, { isLoading }] = useAddNewTaskMutation();

  const [taskId, setTaskId] = useState("");

  const [taskPopUp, setTaskPopUp] = useState(false);

  const [doNothing, setdoNothing] = useState(false);

  const [maxDate, setmaxDate] = useState("");

  const [minDate, setminDate] = useState("");

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
        setTaskPopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  // const today = new Date();
  // const numberOfDaysToAdd = 0;
  // const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  // const defaultDate = new Date(date).toISOString().split("T")[0];

  const today = moment();
  const defaultDate = today.format('YYYY-MM-DDTHH:mm');
  const newMinDate = today.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm');

  useEffect(() => {
    setminDate(newMinDate);
  }, [newMinDate]);

  useEffect(() => {
    setmaxDate(defaultDate);
  }, [defaultDate]);
  const [selectedProject, setSelectedProject] = useState("");
  const [task, settask] = useState({
    currentTaskType: "",
    currentSubTypeTask: "",
    completedDate: defaultDate,
    reason: "",
    comment: "",
    nextTaskType: "",
    deadline: "",
    DProjectID: "",
    DTprojectBlockName: "",
    invenID: "",
    developername: "",
    ammount: 0,
    paymentMode: "",
    bankName: "",
    transactionNo: 0,
    totalAmount: 0,
    leadStatus: "",
    // depositeDate: "",
    status: "",
    attachment: "",
    approvedPayment: "",
    cnicfront: "",
    cnicback: "",
    personalimage: "",
    leadId: "",
    clientId: "",
    clientname: "",
    cnic: "",
    phoneNo: "",
    nationality: "",
    address: "",
  });

  let { data: project } = useGetAllProjectsQuery();

  let { data: singleProject } = useGetSingleProjectQuery(task.DProjectID);

  const [trigger, { data: inventory }] = useLazyGetInventoryByProjectIdQuery();

  useEffect(() => {
    if (singleProject) {
      setloading(false);
    }
  }, [singleProject]);

  useEffect(() => {
    if (task?.DProjectID) {
      setSelectedProject(task.DProjectID);
    }
  }, [task]);

  useEffect(() => {
    if (task.DProjectID || task.DTprojectBlockName) {
      let project = task.DProjectID;
      let block = task.DTprojectBlockName;
      trigger({ project, block });
    }
  }, [task.DProjectID, task.DTprojectBlockName, trigger]);

  let inventoryData = [];
  inventory?.forEach((inven) => {
    if (inven.status === "Available") {
      inventoryData.push({
        unitNo: inven.unitNo,
        id: inven._id,
      });
    }
  });

  useEffect(() => {
    if (task.totalAmount && task.currentSubTypeTask) {
      let calculatedAmount = 0;

      switch (task.currentSubTypeTask) {
        case 'TP':
          calculatedAmount = task.totalAmount * 0.1;
          break;
        case 'PDP':
          calculatedAmount = task.totalAmount * 0.25;
          break;
        case 'DP':
          calculatedAmount = task.totalAmount * 0.25;
          break;
        default:
          calculatedAmount = 0;
      }

      const roundedAmount = parseFloat(calculatedAmount.toFixed(2));

      settask((prevTask) => ({
        ...prevTask,
        ammount: roundedAmount,
      }));
    }
  }, [task.totalAmount, task.currentSubTypeTask]);

  const handleProject = (e) => {
    let { name, value } = e.target;
    setloading(true);
    settask({ ...task, DTprojectBlockName: "", [name]: value });
  };

  let handleChange = (e, closed) => {
    let { name, value } = e.target;

    if (name === "nextTaskType") {
      setdoNothing(value === "doNothing");
    }

    if (name === "paymentMode" && value !== "bank") {
      settask({ ...task, [name]: value, bankName: "" });
    } else if (closed === "closed") {
      settask({
        ...task, nextTaskType:
          "doNothing", [name]: value
      });

    }
    else {
      settask({
        ...task, nextTaskType:
          task.nextTaskType, [name]: value
      });
    }

  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    settask((prevstate) => ({
      ...prevstate,
      [name]: e.target.files[0],
    }));
    e.target.value = '';
  };

  const handleType = (e) => {
    const { value } = e.target;

    if (value === "Call") {
      setsubType([
        "SMS",
        "Call connected",
        "Call Not Connected",
        "Whatsapp",
        "Followed Up",
      ]);
      setclosedLost(false);
      setdoNothing(false);
    } else if (value === "Meeting") {
      setsubType([
        "Vedio Meeting",
        "Head Office Meeting",
        "Outdoor",
        "Site",
        "Walk In",
      ]);
      setclosedLost(false);
      setdoNothing(false);
    } else if (value === "Sales") {
      let filteredSubType = ["TP", "PDP", "DP", "closed-won"];

      if (task?.leadStatus === "TP") {
        filteredSubType = filteredSubType.filter((type) => type !== "TP");
      } else if (task?.leadStatus === "PDP") {
        filteredSubType = filteredSubType.filter(
          (type) => type !== "TP"
        );
      } else if (task?.leadStatus === "DP") {
        filteredSubType = filteredSubType.filter(
          (type) => type !== "TP" && type !== "PDP" && type !== "DP"
        );
      }
      setsubType(filteredSubType);
      setclosedLost(false);
      setdoNothing(false);
    } else if (value === "closed-lost") {
      settask((prevTask) => ({
        ...prevTask,
        nextTaskType: "doNothing",
      }));
      setsubType(["Nothing"]);
      setdoNothing(true);
      setclosedLost(true);
    }

    handleChange(e, value === "closed-lost" ? "closed" : null);
  };


  const handleSubtype = (e) => {
    settokenDownPayment(false);
    if (
      e.target.value === "TP" ||
      e.target.value === "DP" ||
      e.target.value === "PDP"
    ) {
      settokenDownPayment(true);
    }
    handleChange(e);
  };

  const handleSave = () => {
    var formdata = new FormData();

    const completedDate = moment(task.completedDate).utc().format('YYYY-MM-DDTHH:mm:ss');
    formdata.append("currentTaskType", task.currentTaskType);
    formdata.append("currentSubTypeTask", task.currentSubTypeTask);
    formdata.append("completedDate", task.completedDate);
    formdata.append("reason", task.reason);
    formdata.append("comment", task.comment);
    formdata.append("nextTaskType", task.nextTaskType);
    formdata.append("deadline", task.deadline);
    formdata.append("attachment", task.attachment);
    formdata.append("idCardFrontPicture", task.cnicfront);
    formdata.append("idCardBackPicture", task.cnicback);
    formdata.append("personalPicture", task.personalimage);
    formdata.append("cnic", task.cnic);
    formdata.append("address", task.address);
    formdata.append("country", task.nationality);
    formdata.append("phoneNo", task.phoneNo);
    formdata.append("developerName", task.developername);
    formdata.append("projectID", task.DProjectID);
    formdata.append("unitNumber", task.invenID);
    formdata.append("amount", task.ammount);
    formdata.append("totalAmount", task.totalAmount);
    formdata.append("paymentMode", task.paymentMode);
    formdata.append("paymentType", task.currentSubTypeTask);
    formdata.append("bankName", task.bankName);
    formdata.append("transactionId", task.transactionNo);
    formdata.append("paymentproof", task.approvedPayment);
    formdata.append("clientID", task.clientId)
    let id = task.taskId;
    addTask({ formdata, id })
      .unwrap()
      .then((res) => {
        toast.success("task created successfully");
        settask({
          currentTaskType: "",
          currentSubTypeTask: "",
          completedDate: defaultDate,
          reason: "",
          comment: "",
          nextTaskType: "",
          deadline: "",
          DProjectID: "",
          DTprojectBlockName: "",
          invenID: "",
          ammount: "",
          paymentMode: "",
          bankName: "",
          transactionNo: "",
          recieptNo: "",
          depositeDate: "",
          status: "",
          attachment: "",
          approvedPayment: "",
          taskId: task.taskId,
        });
        setpopUp(false);
      })
      .catch((error) => {
        toast.error(error?.data?.error?.message);
      });
  };

  const [pageSize, setPageSize] = useState(20);

  const [popUp, setpopUp] = useState(false);

  const [todoType, settodoType] = useState("today");

  const { data: todolistData, isFetching } = useGetTodoListTaskQuery(todoType);

  const columns = [
    {
      field: "task",
      headerName: "Task",
      width: 150,
    },
    todoType === "Do Nothing"
      ? {
        field: "leadID",
        headerName: "Lead ID",
        width: 150,
      }
      : {
        field: "deadline",
        headerName: "DeadLine",
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              {params.row.deadline ? (
                params.row.deadline.toDateString()
              ) : (
                <>No Deadline</>
              )}
            </div>
          );
        },
      },
    {
      field: "client",
      headerName: "Client",
      width: 130,
      renderCell: (params) => {
        return (
          <Client>
            <Tooltip title={params.row.client}>
              <div>{params.row.client}</div>
            </Tooltip>
            {/* <div>
                <StarIcon sx={
                  {
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`
                  }}/>
              <span>1 lead</span>
            </div> */}
          </Client>
        );
      },
    },
    // {
    //   field: "rating",
    //   headerName: "Rating",
    //   width: 150,
    // },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderCell: (params) => {
        const phoneNo = params.row.phone;
        return phoneNo ? (
          <a href={`tel:+${phoneNo}`} className="text-blue-500 underline">
            {phoneNo}
          </a>
        ) : (
          <span>No phone</span>
        );
      },
    },
    // {
    //   field:'allocatedto',
    //   headerName:'Allocated To',
    //   width:130,
    //   renderCell:(params)=>{
    //     return(
    //       <Allocated>
    //         <div>
    //           {params.row.allocatedto}
    //         </div>
    //         {/* <div>
    //           faisalabad
    //         </div> */}
    //       </Allocated>
    //     )
    //   }
    // },
    {
      field: "classification",
      headerName: "Classification",
      width: 100,
      renderCell: (params) => {
        return params.row.classification === "veryHot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryHot}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "hot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.hot}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "moderate" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.moderate}`,
                }}
              />
            </div>
          </Id>
        ) : params.row.classification === "cold" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.cold}`,
                }}
              />
            </div>
          </Id>
        ) : (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryCold}`,
                }}
              />
            </div>
          </Id>
        );
      },
    },
    {
      field: "project",
      headerName: "Project",
      width: 110,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.project}>
            <div style={{ color: `${colors.textBlue}` }}>
              {params.row.project}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 180,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        let whatsappLink = `https://wa.me/` + params.row.cellNo;
        let mailLink = `mailto:` + params.row.mail;
        return (
          <Action>
            <div
              onClick={() => {
                settask({
                  ...task,
                  taskId: params.row.id,
                  clientId: params.row.clientId,
                  clientname: params.row.client,
                  phoneNo: params.row.cellNo,
                  projectName: params.row.project,
                  DProjectID: params.row.projectID,
                  leadStatus: params.row.leadstatus
                });
                setpopUp(true);
              }}
            >
              <img src="/assets/icons/plus.png" alt="plus" />
            </div>
            <a
              href={whatsappLink}
              onClick="location.href=this.href+'?key='+number;return false;"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon style={{ color: "green", cursor: "pointer" }} />
            </a>
            <a href={mailLink} target="_blank" rel="noreferrer">
              <MailOutlineIcon
                style={{ color: "#1194D1", cursor: "pointer" }}
              />
            </a>
            <ShareIcon style={{ color: "#EC2643", cursor: "pointer" }} />
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setTaskId(params.row.id);
              }}
            />
          </Action>
        );
      },
    },
  ];

  let rows = [];
  todolistData?.forEach((todo) => {
    rows.push({
      id: todo._id,
      task: todo?.nextTask?.nextTaskType,
      deadline: todo?.nextTask?.deadline
        ? new Date(todo?.nextTask?.deadline)
        : null,
      client: todo.client?.fullName,
      clientId: todo.client?._id,
      rating: todo?.client?.clientRating,
      project: todo?.leadID?.leadOpportunity?.project?.projectName,
      projectID: todo?.leadID?.leadOpportunity?.project?._id,
      classification: todo?.leadID?.leadOpportunity?.classification,
      cellNo: todo?.client?.cellNo[0]?.phoneNo,
      mail: todo?.client?.email,
      phone: todo?.client?.cellNo[0]?.phoneNo,
      leadID: todo?.leadID?.leadID,
      leadstatus: todo?.leadID?.leadStatus,


    });
  });

  return (
    <Main>
      <TimeSection>
        <button
          style={{
            backgroundColor:
              todoType === "overdue" ? `${colors.brown}` : `${colors.white}`,
            color:
              todoType === "overdue" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("overdue")}
        >
          Overdue
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "today" ? `${colors.brown}` : `${colors.white}`,
            color: todoType === "today" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("today")}
        >
          Today
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "tomorrow" ? `${colors.brown}` : `${colors.white}`,
            color:
              todoType === "tomorrow" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("tomorrow")}
        >
          Tomorrow
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "7 days" ? `${colors.brown}` : `${colors.white}`,
            color:
              todoType === "7 days" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("7 days")}
        >
          7 Days
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "next month" ? `${colors.brown}` : `${colors.white}`,
            color:
              todoType === "next month" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("next month")}
        >
          Next Month
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "Do Nothing" ? `${colors.brown}` : `${colors.white}`,
            color:
              todoType === "Do Nothing" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("Do Nothing")}
        >
          Do Nothing
        </button>
      </TimeSection>
      <LeadsSection>
        Todo List:<span> {todolistData?.length} </span>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isFetching}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross
            onClick={() => {
              settask({
                currentTaskType: "",
                currentSubTypeTask: "",
                completedDate: defaultDate,
                subType: "",
                reason: "",
                comment: "",
                nextTaskType: "",
                developername: "",
                deadline: "",
                DProjectID: "",
                cnic: "",
                nationality: "",
                address: "",
                cnicfront: "",
                cnicback: "",
                personalimage: "",
                DTprojectBlockName: "",
                invenID: "",
                ammount: 0,
                paymentMode: "",
                bankName: "",
                transactionNo: 0,
                totalAmount: 0,
                leadStatus: "",
                status: "",
                attachment: "",
                approvedPayment: "",
                leadId: task.leadId,
              });
              setpopUp(false);
            }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            <Section>
              <Task>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Current Task</h3>
                  <InputFeild>
                    <h3>Type:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="currentTaskType"
                        id="currentTaskType"
                        value={task.currentTaskType}
                        onChange={handleType}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Call">Call</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Sales">Sales</option>
                        <option value="closed-lost">Closed (Lost)</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Sub-Type:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="currentSubTypeTask"
                        id="currentSubTypeTask"
                        value={task.currentSubTypeTask}
                        onChange={handleSubtype}
                      >
                        <option value="">Select</option>
                        {subType?.map((ls) => (
                          <option value={ls} key={ls}>
                            {ls}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Completed Date:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <input
                        type="datetime-local"
                        name="completedDate"
                        id="completedDate"
                        value={task.completedDate || defaultDate}
                        onChange={handleChange}
                        min={newMinDate}
                        max={defaultDate}
                      />
                    </div>
                  </InputFeild>
                  {closedLost ? (
                    <InputFeild>
                      <h3>Reason:<b style={{ color: "red" }}>*</b></h3>
                      <input
                        type="text"
                        name="reason"
                        id="reason"
                        placeholder="Type Here..."
                        value={task.comment}
                        onChange={handleChange}
                      />
                    </InputFeild>
                  ) : null}
                  {tokenDownPayment === false ? (
                    <>
                      <InputFeild>
                        <h3>Comment:<b style={{ color: "red" }}>*</b></h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                      {
                        task.currentTaskType === 'Meeting' || task.currentTaskType === "Sales" || task.currentSubTypeTask === "Whatsapp" ?
                          <InputFeild>
                            <h3>Attachment:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="attachment">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.attachment && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.attachment)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="attachment"
                              id="attachment"
                              onChange={handleFileChange}
                            />
                          </InputFeild> : null}
                    </>
                  ) : null}
                  {tokenDownPayment ? (
                    <>
                      {/* <InputFeild>
                        <Headinginfo>Project Details</Headinginfo>
                      </InputFeild> */}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <Heading>Project Details</Heading>
                          <InputFeild>
                            <h3>
                              Project:<b style={{ color: "red" }}>*</b>
                            </h3>
                            <div>
                              <select
                                name="DProjectID"
                                id="DProjectID"
                                disabled
                                value={selectedProject}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSelectedProject(value);
                                  settask((prevTask) => ({
                                    ...prevTask,
                                    DProjectID: value,
                                  }));
                                }}
                              >
                                <option value="">Select Project</option>
                                {project?.map((pro) => (
                                  <option key={pro._id} value={pro._id}>
                                    {pro.projectName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </InputFeild>
                        </>
                      )}

                      {loading ? (
                        <TaskLoader>
                          <Puff
                            height="40"
                            width="40"
                            radius={1}
                            color={colors.brown}
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </TaskLoader>
                      ) : null}
                      {singleProject?.projectBlock?.length > 0 ? (
                        <InputFeild>
                          <h3>Project Block:</h3>
                          <div>
                            <select
                              name="DTprojectBlockName"
                              id="DTprojectBlockName"
                              onChange={handleChange}
                            >
                              <option value="">select</option>
                              {singleProject?.projectBlock?.map((pro) => (
                                <option value={pro.blockName}>
                                  {pro.blockName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </InputFeild>
                      ) : null}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Unit: <b style={{ color: "red" }}>*</b></h3>
                          <div>
                            {/* <select
                            name="invenID"
                            id="invenID"
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {inventoryData?.map((inven) => (
                              <option value={inven.id}>{inven.unitNo}</option>
                            ))}
                          </select> */}
                            <input
                              type="text"
                              name="invenID"
                              id="invenID"
                              value={task.invenID}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Developer Name:<b style={{ color: "red" }}>*</b></h3>
                          <div>
                            <input
                              type="text"
                              name="developername"
                              id="developername"
                              value={task.developername}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Total Amount:<b style={{ color: "red" }}>*</b></h3>
                          <div>
                            <input
                              type="number"
                              name="totalAmount"
                              id="totalAmount"
                              value={task.totalAmount}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <Heading>Client Details</Heading>
                          <InputFeild>
                            <h3>
                              Client Name: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="text"
                              name="clientname"
                              id="clientname"
                              disabled
                              value={task.clientname || ''}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Phone: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="number"
                              name="phone"
                              id="phone"
                              disabled
                              value={task.phoneNo}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              CNIC: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="number"
                              name="cnic"
                              id="cnic"
                              value={task.cnic}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Nationality: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="text"
                              name="nationality"
                              id="nationality"
                              value={task.nationality}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Address: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <textarea
                              name="address"
                              id="address"
                              placeholder="type here..."
                              value={task.address}
                              onChange={handleChange}
                            ></textarea>
                          </InputFeild>
                        </>
                      )}

                      <Heading>Add Payment</Heading>
                      <InputFeild>
                        <h3>Payment Mode: <b style={{ color: "red" }}>*</b></h3>
                        <div>
                          <select
                            name="paymentMode"
                            id="paymentMode"
                            onChange={handleChange}
                            value={task.paymentMode}
                          >
                            <option value="">Select</option>
                            <option value="online">Online</option>
                            <option value="cheque">Cheque</option>
                            <option value="cash">Cash</option>
                            <option value="bank">Bank Transfer</option>
                          </select>
                        </div>
                      </InputFeild>
                      {task.paymentMode === "bank" && (
                        <BankInputFeild>
                          <h3>
                            Bank Name: <b style={{ color: "red" }}>*</b>
                          </h3>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={Banks}
                            value={task.bankName}
                            onChange={(event, newValue) =>
                              settask({ ...task, bankName: newValue })
                            }
                            sx={{
                              width: "100%",
                              border: `1px solid ${colors.inputBorderWhite}`,
                              borderRadius: "5px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ backgroundColor: `${colors.inputWhite}` }}
                              />
                            )}
                          />
                        </BankInputFeild>
                      )}
                      <InputFeild>
                        <h3>Amount:<b style={{ color: "red" }}>*</b></h3>
                        <input
                          type="text"
                          name="ammount"
                          id="ammount"
                          value={task.ammount}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Transaction No:<b style={{ color: "red" }}>*</b></h3>
                        <input
                          type="number"
                          name="transactionNo"
                          id="transactionNo"
                          value={task.transactionNo}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      {/* <InputFeild>
                        <h3>Deposite Date:<b style={{ color: "red" }}>*</b></h3>
                        <div>
                          <input
                            type="date"
                            name="depositeDate"
                            id="depositeDate"
                            value={task.depositeDate}
                            onChange={handleChange}
                          />
                        </div>
                      </InputFeild> */}
                      {task.currentSubTypeTask === "DP" && (
                        <InputFeild>
                          <h3>Personal Image:<b style={{ color: "red" }}>*</b></h3>
                          <span>
                            <p>upload file here</p>
                            <label htmlFor="personalimage">
                              <AttachFileIcon /> Upload
                            </label>
                            {task.personalimage && (
                              <UploadedFile
                                src={URL.createObjectURL(task.personalimage)}
                                alt="uploaded file"
                              />
                            )}
                          </span>
                          <input
                            type="file"
                            name="personalimage"
                            id="personalimage"
                            onChange={handleFileChange}
                          />
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <InputFeild>
                            <h3>CNIC Front:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="cnicfront">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.cnicfront && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.cnicfront)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="cnicfront"
                              id="cnicfront"
                              onChange={handleFileChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>CNIC Back:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="cnicback">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.cnicback && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.cnicback)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="cnicback"
                              id="cnicback"
                              onChange={handleFileChange}
                            />
                          </InputFeild>
                        </>
                      )}
                      <InputFeild>
                        <h3>Attach Payment Proof:<b style={{ color: "red" }}>*</b></h3>
                        <span>
                          <p>upload file here</p>
                          <label htmlFor="approvedPayment">
                            <AttachFileIcon /> Upload
                          </label>
                          {task.approvedPayment && (
                            <UploadedFile
                              src={URL.createObjectURL(task.approvedPayment)}
                              alt="uploaded file"
                            />
                          )}
                        </span>
                        <input
                          type="file"
                          name="approvedPayment"
                          id="approvedPayment"
                          onChange={handleFileChange}
                        />
                        <p>
                          Note: Invalid payment proof will result in
                          cancellation of payment and inventory will released
                          immediately.
                        </p>
                      </InputFeild>
                      <InputFeild>
                        <h3>Comment:<b style={{ color: "red" }}>*</b></h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                      {/* <FixedContainer>
                        <Price>
                          <h4>Unit Price:</h4>
                          <p>PKR 1230000</p>
                        </Price>
                        <InputFeild>
                          <h3>Unit Status:</h3>
                          <div>
                            <select
                              name="status"
                              id="status"
                              value={task.status}
                              onChange={handleChange}
                            >
                              <option value="downPayment" disabled>
                                downPayment
                              </option>
                            </select>
                          </div>
                        </InputFeild>
                        <InputFeild>
                          <h3>Unit Discount</h3>
                          <input
                            type="text"
                            value={"0"}
                            name="uniDiscount"
                            id="unitDiscount"
                            disabled
                          />
                        </InputFeild>
                        <InputFeild>
                          <h3>Deal Price</h3>
                          <input
                            type="text"
                            value={"342324"}
                            name="dealPrice"
                            id="dealPrice"
                            disabled
                          />
                        </InputFeild>
                        <Price>
                          <h4>Due Amount:</h4>
                          <p>PKR 1230000</p>
                        </Price>
                      </FixedContainer> */}
                    </>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    settask({
                      currentTaskType: "",
                      leadStatus: "",
                      currentSubTypeTask: "",
                      completedDate: defaultDate,
                      subType: "",
                      reason: "",
                      comment: "",
                      nextTaskType: "",
                      developername: "",
                      deadline: "",
                      DProjectID: "",
                      cnic: "",
                      nationality: "",
                      address: "",
                      cnicfront: "",
                      cnicback: "",
                      personalimage: "",
                      DTprojectBlockName: "",
                      invenID: "",
                      ammount: 0,
                      totalAmount: 0,
                      paymentMode: "",
                      bankName: "",
                      transactionNo: 0,
                      leadStatus: "",
                      status: "",
                      attachment: "",
                      approvedPayment: "",
                      leadId: task.leadId,
                    });
                    setpopUp(false);
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task style={{ borderRight: "none" }}>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Next Task</h3>
                  <InputFeild>
                    <h3>Next Task:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="nextTaskType"
                        id="nextTaskType"
                        value={task.nextTaskType || ""}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="doNothing">Do Nothing</option>
                        <option value="followUp">Follow Up</option>
                        <option value="contractedClient">
                          Contracted Client
                        </option>
                        <option value="meetClient">Meet Client</option>
                        <option value="recievePartialDownPayment">
                          Recieve Partial Down Payment
                        </option>
                        <option value="closed-won">Close Won</option>
                        <option value="recieveCompleteDownPayment">
                          Recieve Complete Down Payment
                        </option>
                        <option value="arrangeMeeting">Arrange Meeting</option>
                        <option value="receivetokenPayment">
                          Receive Token Payment
                        </option>
                      </select>
                    </div>
                  </InputFeild>
                  {doNothing === false ? (
                    <InputFeild>
                      <h3>Deadline:<b style={{ color: "red" }}>*</b></h3>
                      <div>
                        <input
                          type="datetime-local"
                          name="deadline"
                          id="deadline"
                          value={task.deadline}
                          onChange={handleChange}
                          min={new Date().toISOString().slice(0, 16)}
                        />
                      </div>
                    </InputFeild>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <>Saving...</> : <>Save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TaskTable taskId={taskId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    overflow: hidden;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  span {
    font-weight: bold;
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`;

const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${colors.textBlue};
  }
  p {
    color: ${colors.textMed};
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  border-bottom: 1px solid ${colors.inputFeild};
  border-right: 1px solid ${colors.inputFeild};
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;

const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  color: ${colors.textBlue};
  font-size: clamp(1.5em, 2vh, 3em);
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const FixedContainer = styled.div`
  width: 100%;
  min-height: 3em;
  margin-top: 1em;
  background-color: ${colors.inputBorderWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const Price = styled.div`
  display: flex;
  p {
    margin-left: 2em;
  }
  margin-top: 1em;
`;

const TaskLoader = styled.div`
  margin-top: 1em;
`;
const BankInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 50%;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
