import { apiSlice } from './api/apiSlice'

const authSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        login: builder.mutation({
            query:(credentials)=>({
                url:'auth/login',
                method: 'POST',
                body: credentials
            })
        }),
        registerUser:builder.mutation({
            query:(credentials)=>({
                url:'auth/register',
                method: 'POST',
                body: credentials
            })
        }),
        changePassword:builder.mutation({
            query:(email)=>({
                url:'auth/change-password-req',
                method:'POST',
                body:email
            })
        }),
        changePasswordCred:builder.mutation({
            query:(body)=>({
                url:'/auth/change-password',
                method:'PATCH',
                body:body
            })
        })
    })
})

export const { useLoginMutation,useRegisterUserMutation,useChangePasswordMutation,useChangePasswordCredMutation } = authSlice;
