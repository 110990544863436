import React from 'react'
import styled from 'styled-components'
import { SharedLeadsDataTable } from '../../Components/Leads/shared leads/SharedLeadsDataTable'
import { colors } from '../../Shared/colors'

export const SharedLeadsComponent = () => {
  return (
    <Main>
        <SharedLeadsDataTable/>
    </Main>
  )
}

const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
padding-top: 2em;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`

