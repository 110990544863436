import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useGetSingleHierarchyQuery } from "../../features/employeeSlice";
import { useAddtargetMutation } from "../../features/targetSlice";
import { useGetAllProjectsQuery } from "../../features/projectSlice";
import toast from "react-hot-toast";
import { decryptData } from "../../services/crypto";

export const TargetFirst = () => {
  let role = null;
    let storedStaffRole = localStorage.getItem("role");
  
    if (storedStaffRole) {
      try {
        role = decryptData(storedStaffRole);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }
  
  const [targetPopUp, settargetPopUp] = useState(false);
  const [targetData, settargetData] = useState({
    targetEmployee: { label: "", id: "" },
    targetInRevenue: "",
    project:""
  });

  
  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  
  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });
  const options = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const { data: project } = useGetAllProjectsQuery()

  const [createTarget, { isLoading: targetLoading }] = useAddtargetMutation();
  const handleTarget = () => {
    const body = {
      employeeID: targetData.targetEmployee.id,
      projectID: targetData.project,
      targetInRevenue: targetData.targetInRevenue
  }
  createTarget(body).unwrap()
  .then((res)=>{
    toast.success("target created successfully!")
  })
  .catch((err)=>{
    toast.error("Error creating target")
  })
  .finally(()=>{
    settargetData({
      targetEmployee: { label: "", id: "" },
      targetInRevenue: "",
      project:""
    });
  })
  };
  return (
    <Main>
      <Search>
        <TrgetBtnSection>
          <SubHeading>Targets | Revenue</SubHeading>
          {role === "hr" ||
          role === "v3" ||
          role === "developer" ||
          role === "director" ||
          role === "ceo" ? (
            <button onClick={() => settargetPopUp(!targetPopUp)}>
              <AddIcon /> Add Target
            </button>
          ) : null}
        </TrgetBtnSection>
        <SearchSection1>
          <InputSection>
            <h3>Target Month:</h3>
            <div>
              <select name="targetMonth" id="targetMonth">
                <option value="">Select</option>
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Team Type:</h3>
            <div>
              <select name="teamType" id="unitLocation">
                <option value="">Select</option>
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Staff:</h3>
            <div>
              <select name="staff" id="staff">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
        </SearchSection1>
        <SearchSection2>
          <InputSection>
            <h3>Target Is:</h3>
            <div>
              <select name="targetIs" id="targetIs">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <Button>Search</Button>
        </SearchSection2>
      </Search>
      <PopUpContainer popUp={targetPopUp}>
        <NumberPopUp style={{ height: "60vh" }}>
          <Cross
            onClick={() => settargetPopUp(!targetPopUp)}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <div style={{ padding: "2em" }}>
            <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={options}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  settargetData({ ...targetData, targetEmployee: value });
                }}
                value={targetData.targetEmployee}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>

            <InputFeild>
              <h3>Project:</h3>
              <div>
                <select
                  name="project"
                  id="project"
                  value={targetData.project}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    settargetData({ ...targetData, project: newValue });
                  }}
                >
                  <option value="">select</option>
                  {project?.map((proj) => (
                    <option value={proj._id}>{proj.projectName}</option>
                  ))}
                </select>
              </div>
            </InputFeild>
            <InputFeild>
              <h3>Revenue:</h3>

              <input
                type="text"
                name="targetInRevenue"
                id="targetInRevenue"
                max={100}
                value={targetData.targetInRevenue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  settargetData({ ...targetData, targetInRevenue: newValue });
                }}
              />
            </InputFeild>
            <ButtonPart>
              <button
                style={{
                  backgroundColor: `${colors.textBlue}`,
                  color: `${colors.white}`,
                  marginTop: "1em",
                  opacity: targetLoading ? "0.5" : "1",
                }}
                onClick={handleTarget}
                disabled={targetLoading}
              >
                {targetLoading ? <>asigning...</> : <>asign</>}
              </button>
            </ButtonPart>
          </div>
        </NumberPopUp>
      </PopUpContainer>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const TrgetBtnSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const NumberPopUp = styled.div`
  width: 30vw;
  height: 100vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;


const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;


const StaffInputFeild = styled.div`
  /* width: 85%; */
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;
