import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { colors } from "../../Shared/colors";
import { useGetAlltargetsQuery } from "../../features/targetSlice";
import { decryptData } from "../../services/crypto";

export const TargetsDataTable = () => {
  const [pageSize, setPageSize] = useState(5);

 
  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  
  const { data: target } = useGetAlltargetsQuery(staffId);
  const columns = [
    {
      field: "assigningDate",
      headerName: "assigningDate",
      width: 200,
      renderCell: (params) => {
        const dateString = params.row.assigningDate;

        const dateObject = new Date(dateString);

        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        const hours = ("0" + dateObject.getHours()).slice(-2);
        const minutes = ("0" + dateObject.getMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getSeconds()).slice(-2);

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "targetInRevenue",
      headerName: "targetInRevenue",
      width: 200,
    },
    {
      field: "manager",
      headerName: "manager",
      width: 200,
    },
  ];

  const rows = useMemo(() => {
    let rows = [];
    target?.empTarget?.targets.forEach((tar) => {
      rows.push({
        id: tar?._id,
        assigningDate: tar?.assigningDate,
        targetInRevenue: tar?.targetInRevenue,
        manager: target?.hierarchy?.manager || "",
      });
    });
    return rows;
  }, [target]);

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Targets:<span> {target?.empTarget?.targets.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          sx={{
            border: "none",
          }}
        />
      </Table>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  select {
    outline: none;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
  }
`;

const Table = styled.div`
  height: 80%;
`;
