import React, { createContext, useContext, useState } from 'react';

// Step 1: Create a new context
const StateContext = createContext();

// Step 2: Create a provider component
export const SelectedStaffProvider = ({ children }) => {

  const [selectedStaff, setSelectedStaff] = useState({});
  const [selectedStaffOverdue, setselectedStaffOverdue] = useState([])
  const [emailData, setemailData] = useState({})

  const updateEmailData = (data) =>{
    setemailData(data)
  }
  const updateSelectedStaff = (staffObject) => {
    setSelectedStaff(staffObject);
  };

  const updateOverdueTask = (overdue) =>{
    setselectedStaffOverdue(overdue)
  }
  const contextValue = {
    selectedStaff,
    selectedStaffOverdue,
    emailData,
    updateEmailData,
    updateSelectedStaff,
    updateOverdueTask
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export const useSelectedStaffContext = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
};
