import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../Shared/colors";
import { useGetHierarchyCompletedTaskQuery } from "../../features/taskSlice";
import { useAddFeedbackMutation } from "../../features/leadsSlice";
import toast from "react-hot-toast";
import moment from "moment-timezone";
import styled from "styled-components";

// Define columns
const columns = [
  {
    field: "client",
    headerName: "Client",
    width: 150,
    renderCell: (params) => (
      <Tooltip title={params.row.client}>
        <div>{params.row.client}</div>
      </Tooltip>
    ),
  },
  {
    field: "cellNo",
    headerName: "Cell No",
    width: 150,
    renderCell: (params) => <div>{params.row.cellNo}</div>,
  },
  {
    field: "completedDate",
    headerName: "Completed Date",
    width: 200,
    renderCell: (params) => {
      let date = moment(params.row.completedDate).tz('Asia/Karachi');
      let formattedDate = date.format('ddd, MMM D, YYYY h:mm A');
      return <div>{formattedDate}</div>;
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 150,
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 150,
    renderCell: (params) => (
      <Tooltip title={params.row.comment}>
        <div>{params.row.comment}</div>
      </Tooltip>
    ),
  },
  {
    field: "attachment",
    headerName: "Attachment",
    width: 150,
    renderCell: (params) => (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {params.row.attachment ? (
          <a
            href={params.row.attachment}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: colors.brown,
              fontWeight: 'bolder',
              border: `1px solid ${colors.brown}`,
              padding: '0.5em',
              borderRadius: '5px',
            }}
          >
            Click to View
          </a>
        ) : (
          <div>No Pic</div>
        )}
      </div>
    ),
  },
];

export const TaskTable = ({ leadId }) => {
  const { data: taskData, isFetching, isError, error } = useGetHierarchyCompletedTaskQuery(leadId);
  const [pageSize, setPageSize] = useState(20);
  const [feedbackPopUp, setFeedbackPopUp] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [addFeedback, { isLoading }] = useAddFeedbackMutation();

  const handleAddFeedback = () => {
    if (!feedback || !leadId) return;
    let body = { text: feedback };

    addFeedback({ leadId, body })
      .unwrap()
      .then(() => {
        toast.success("Feedback added successfully!");
      })
      .catch(() => {
        toast.error("Failed to add feedback!");
      })
      .finally(() => {
        setFeedback("");
        setFeedbackPopUp(false);
      });
  };

  let rows = [];
  if (taskData) {
    let client = taskData[0]?.client.fullName;
    let cellNo = taskData[0]?.client?.cellNo[0]?.phoneNo;

    taskData[0]?.currentTask.forEach((task) => {
      rows.push({
        id: task._id,
        client: client,
        cellNo: cellNo,
        currentTaskType: task?.currentTaskType,
        currentSubTypeTask: task?.currentSubTypeTask,
        completedDate: new Date(task?.completedDate),
        reason: task?.reason,
        comment: task?.comment,
        attachment: task?.attachment,
      });
    });
  }

  return (
    <>
      <Main>
        <AddFeedback onClick={() => setFeedbackPopUp(true)}>
          Add Feedback
        </AddFeedback>
        <Table>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            disableSelectionOnClick
            loading={isFetching}
            sx={{
              border: "none", // Apply border style
            }}
          />
        </Table>
      </Main>
      <PopUpContainer popUp={feedbackPopUp}>
        <PopUp style={{ height: "50%", width: "50%" }}>
          <Cross
            onClick={() => setFeedbackPopUp(!feedbackPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection
            style={{ height: "90%", display: "flex", flexDirection: "column", padding: "5px" }}
          >
            {/* <ReactQuill theme="snow" value={feedback} onChange={setfeedback} />; */}
            <textarea
              name="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please enter your feedback..."
              style={{ height: "80%", padding: "5px" }}
            ></textarea>
            <button onClick={handleAddFeedback}
            >{isLoading ? "Adding..." : "Add Feedback"}
            </button>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </>
  );
};

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 1em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`
const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 15px;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: 1em;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
  button {
    background-color: ${colors.brown};
    color: ${colors.white};
    border: none;
    height: 2em !important; 
    border-radius: 5px;
    width: 8em;
    margin-left: auto;
    cursor: pointer;
  }
`;
const AddFeedback = styled.button`
   background-color: ${colors.brown};
    color: ${colors.white};
    border: none;
    height: 3em !important; 
    border-radius: 5px;
    width: 8em;
    margin-left: auto;
    cursor: pointer;
    display: flex; 
   justify-content: flex-end;
   padding: 1em;
    
`

const Table = styled.div`
height: 80%;
`