import { apiSlice } from "./api/apiSlice";

const regionSLice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createRegion:builder.mutation({
            query:(region)=>({
                url:'region/create',
                method:'POST',
                body:region,
            }),
            invalidatesTags:['Region']
        }),

        getAllRegion:builder.query({
            query:()=>({
                url:'region',
            }),
            transformResponse:(response,meta,arg)=>response.region,
            providesTags:['Region']
        }),

        getSingleRegion:builder.query({
            query:(id)=>({
                url:`region/${id}`
            }),
            providesTags:['Region']
        })
    })
})

export const { useCreateRegionMutation, useGetAllRegionQuery, useGetSingleRegionQuery } = regionSLice;