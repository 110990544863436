import { apiSlice } from "./api/apiSlice";

const clientSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchClient: builder.query({
      query: (phone) => ({
        url: `client/search?phoneNo=${phone}`,
      }),
      transformResponse: (response) => response.clients,
    }),
    addClient: builder.mutation({
      query: (lead) => ({
        url: "client/create",
        method: "POST",
        body: lead,
      }),
      invalidatesTags: ["Client"],
    }),
    getAllClients: builder.query({
      query: ({ employeeId, page, pageSize, fullName, phoneNumber }) => ({
        url: `client?employeeId=${employeeId}&pageSize=${pageSize}&page=${page}&fullName=${fullName}&phoneNumber=${phoneNumber}`,
      }),
      transformResponse: (response, meta, arg) => response,
      providesTags: ["Client"],
   }),
    editClient: builder.mutation({
      query: ({ id, body }) => ({
        url: `client/edit_client_attribute/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteClient: builder.mutation({
      query: (clientId) => ({
        url: `/client/delete/${clientId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Client"],
    }),
  }),
});

export const {
  useSearchClientQuery,
  useAddClientMutation,
  useGetAllClientsQuery,
  useLazyGetAllClientsQuery,
  useEditClientMutation,
  useDeleteClientMutation,
} = clientSlice;
