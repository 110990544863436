import React from 'react'
import styled from 'styled-components'
import { SearchLeadsDataTable } from '../../Components/Leads/Search leads/SearchLeadsDataTable'
import { SearchLeadsFirst } from '../../Components/Leads/Search leads/SearchLeadsFirst'
import { colors } from '../../Shared/colors'

export const SearchLeadsComponent = () => {
  return (
    <Main>
      <SearchLeadsFirst/>
      {/* <SearchLeadsDataTable/> */}
    </Main>
  )
}

const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
padding-top: 2em;
display: flex;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`

